import React, { useEffect, useState } from "react";
import "./style.css";

import axios from "axios";
import "./register.css";
import {  Link } from "react-router-dom";
import BrandList from "../components/auth/BrandList";
import LoginCard from "../components/auth/LoginCard";
import SummaryCard from "../components/auth/SummaryCard";
import toast from "react-hot-toast";

const Login = () => {
      const [detail, setDetail] = useState([]);
      const [pageId, setPageId] = useState("seller-inquery");
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/${pageId}`,

                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setDetail(response.data.page);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [pageId]);

      return (
            <>
                  <div className="page-ath">
                        <div className="page-ath-wrap">
                              <div className="page-ath-content">
                                    <div className="my-auto">
                                          <div
                                                className="page-ath-header"
                                                style={{
                                                      width: "320px",
                                                      height: "auto",
                                                }}
                                          >
                                                <Link
                                                      to="/"
                                                      className="page-ath-logo"
                                                >
                                                      <img
                                                            src="/images/logo.jpg"
                                                            className="w-100"
                                                            alt="Seller Center - eSecurity Cart"
                                                      />
                                                </Link>
                                          </div>
                                          <LoginCard />
                                    </div>
                              </div>

                              <div className="page-ath-gfx">
                                    <div className="d-flex align-items-center justify-content-center w-100 p-3">
                                          <div className="col-md-12">
                                                <SummaryCard
                                                      title="Seller Inquery"
                                                      detail={detail}
                                                />
                                                <BrandList
                                                      setPageId={setPageId}
                                                />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Login;
