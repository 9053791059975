import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
import ProfileNavigation from "../../components/profile/ProfileNavigation";

import axios from "axios";
import $ from "jquery";
import { FormButton } from "../../components/form";
import toast from "react-hot-toast";
import UserContext from "../../stores/UserContext";
import { Form } from "react-bootstrap";

const options = [
      {
            slug: "province-first",
            name: "Province 1st",
      },
      {
            slug: "province-second",
            name: "Madesh Province",
      },
      {
            slug: "province-third",
            name: "Bagmati Province",
      },
      {
            slug: "province-fourth",
            name: "Gandaki Province",
      },
      {
            slug: "province-fifth",
            name: "Lumbini Province",
      },
      {
            slug: "province-sixth",
            name: "Karnali Province",
      },
      {
            slug: "province-seventh",
            name: "Sudurpashchim Province",
      },
];

const WarehouseAddress = () => {
      const userCtx = useContext(UserContext);

      const [fullName, setFullName] = useState("");
      const [address, setAddress] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [email, setEmail] = useState("");
      const [city, setCity] = useState("");
      const [state, setState] = useState("");

      const [loading, setLoading] = useState(false);
      const [isLoading, setIsLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/warehouse/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setFullName(response.data.seller.full_name);
                              setAddress(response.data.seller.address);
                              setPhoneNumber(response.data.seller.phone_number);
                              setEmail(response.data.seller.email);
                              setCity(response.data.seller.city);
                              setState(response.data.seller.state);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setIsLoading(true);
            event.preventDefault();

            const data = {
                  full_name: fullName,
                  email: email,
                  address: address,
                  phone_number: phoneNumber,
                  city: city,
                  state: state,
            };
            $("button[type=submit]").prop("disabled", true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/warehouse/details`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (response.data.message.full_name) {
                                                toast.error(
                                                      response.data.message
                                                            .full_name
                                                );
                                          }
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                          if (response.data.message.address) {
                                                toast.error(
                                                      response.data.message
                                                            .address
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .phone_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (response.data.message.city) {
                                                toast.error(
                                                      response.data.message.city
                                                );
                                          }
                                          if (response.data.message.state) {
                                                toast.error(
                                                      response.data.message
                                                            .state
                                                );
                                          }
                                    }
                              }
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
      };

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard d-flex mb-0">
                                    <ProfileNavigation />
                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">
                                                Warehouse Adress
                                          </h5>
                                          <Form onSubmit={handleFormSubmit}>
                                                <FormInput
                                                      label="Full Name"
                                                      required={true}
                                                      classes="mb-3"
                                                      value={fullName}
                                                      handleInput={setFullName}
                                                />
                                                <FormInput
                                                      label="Address"
                                                      required={true}
                                                      classes="mb-3"
                                                      value={address}
                                                      handleInput={setAddress}
                                                />
                                                <FormInput
                                                      label="Contact Mobile No."
                                                      required={true}
                                                      classes="mb-3"
                                                      value={phoneNumber}
                                                      handleInput={
                                                            setPhoneNumber
                                                      }
                                                />
                                                <FormInput
                                                      label="Email Address"
                                                      required={true}
                                                      classes="mb-3"
                                                      value={email}
                                                      handleInput={setEmail}
                                                />
                                                <FormInput
                                                      label="City / Town"
                                                      required={true}
                                                      classes="mb-3"
                                                      value={city}
                                                      handleInput={setCity}
                                                />
                                                <FormSelect
                                                      data={options}
                                                      label="Province / State"
                                                      required={true}
                                                      value={state}
                                                      handleSelect={setState}
                                                />
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default WarehouseAddress;
