import React, { useState, useEffect, useContext } from "react";

import $ from "jquery";
import { useParams } from "react-router-dom";
import FormInput from "../../components/form/FormInput";
import FormTitle from "../../components/form/FormTitle";
import axios from "axios";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import UserContext from "../../stores/UserContext";
import { Col, Form, Row } from "react-bootstrap";
import { FormButton } from "../../components/form";

const ContactEdit = () => {
      const { contactId } = useParams();
      const userCtx = useContext(UserContext);

      const [contact, setContact] = useState([]);

      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");
      const [email, setEmail] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [address, setAddress] = useState("");
      const [company, setCompany] = useState("");
      const [jobTitle, setJobTitle] = useState("");
      const [notes, setNotes] = useState("");

      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/contacts/${contactId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setFirstName(response.data.contact.first_name);
                              setLastName(response.data.contact.last_name);
                              setEmail(response.data.contact.email);
                              setPhoneNumber(
                                    response.data.contact.phone_number
                              );
                              setAddress(response.data.contact.address);
                              setCompany(response.data.contact.company_name);
                              setJobTitle(response.data.contact.job_title);
                              setNotes(response.data.contact.notes);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, [contactId]);

      async function handleFormSubmit(event) {
            setLoading(true);
            event.preventDefault();
            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone_number: phoneNumber,
                  company: company,
                  address: address,
                  job_title: jobTitle,
                  notes: notes,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/contacts/${contactId}/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message.first_name) {
                                          toast.error(
                                                response.data.message.first_name
                                          );
                                    }
                                    if (response.data.message.last_name) {
                                          toast.error(
                                                response.data.message.last_name
                                          );
                                    }
                                    if (response.data.message.email) {
                                          toast.error(
                                                response.data.message.email
                                          );
                                    }
                                    if (response.data.message.phone_number) {
                                          toast.error(
                                                response.data.message
                                                      .phone_number
                                          );
                                    }
                                    if (response.data.message.company) {
                                          toast.error(
                                                response.data.message.company
                                          );
                                    }
                                    if (response.data.message.address) {
                                          toast.error(
                                                response.data.message.address
                                          );
                                    }
                                    if (response.data.message.job_title) {
                                          toast.error(
                                                response.data.message.job_title
                                          );
                                    }
                                    if (response.data.message.notes) {
                                          toast.error(
                                                response.data.message.notes
                                          );
                                    }
                              }
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setLoading(false);
                        }, 3000);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <Row>
                                                <Col md={12}>
                                                      <div className="form__header">
                                                            <h1 className="form__header-title">
                                                                  Edit Contact
                                                            </h1>
                                                      </div>
                                                      <Form
                                                            onSubmit={
                                                                  handleFormSubmit
                                                            }
                                                      >
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Description"
                                                                        summary="Add your personal detail and necessary information from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormInput
                                                                              label="First Name"
                                                                              value={
                                                                                    firstName
                                                                              }
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setFirstName
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Last Name"
                                                                              classes="mb-3"
                                                                              value={
                                                                                    lastName
                                                                              }
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setLastName
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Address"
                                                                              value={
                                                                                    address
                                                                              }
                                                                              classes="mb-3"
                                                                              type="text"
                                                                              handleInput={
                                                                                    setAddress
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Email Address"
                                                                              classes="mb-3"
                                                                              value={
                                                                                    email
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setEmail
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Phone No."
                                                                              classes="mb-3"
                                                                              value={
                                                                                    phoneNumber
                                                                              }
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setPhoneNumber
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Company"
                                                                              value={
                                                                                    company
                                                                              }
                                                                              classes="mb-3"
                                                                              type="text"
                                                                              handleInput={
                                                                                    setCompany
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Job Title"
                                                                              classes="mb-3"
                                                                              value={
                                                                                    jobTitle
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setJobTitle
                                                                              }
                                                                        />
                                                                        <div className="mb-4">
                                                                              <label className="form__group-label">
                                                                                    Description
                                                                              </label>
                                                                              <textarea
                                                                                    className="form__group-message"
                                                                                    autoComplete="off"
                                                                                    autoCorrect="off"
                                                                                    value={
                                                                                          notes
                                                                                    }
                                                                                    autoCapitalize="off"
                                                                                    spellCheck="false"
                                                                                    rows="4"
                                                                                    onChange={(
                                                                                          event
                                                                                    ) =>
                                                                                          setNotes(
                                                                                                event
                                                                                                      .target
                                                                                                      .value
                                                                                          )
                                                                                    }
                                                                              ></textarea>
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <div className="mb-4 text-end">
                                                                  <FormButton
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />
                                                            </div>
                                                      </Form>
                                                </Col>
                                          </Row>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ContactEdit;
