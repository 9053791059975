import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/dashboard/Sidebar";

const PageNotFound = () => {
      const user = JSON.parse(localStorage.getItem("userData"));

      return (
            <>
                  <div className="d-flex">
                        {user && <Sidebar />}
                        <div className={user ? "header" : "w-100"}>
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12 mx-auto text-center my-5 py-5 text-secondary">
                                                      <h1 className="fs-2">
                                                            Page Not Found
                                                      </h1>
                                                      <Link
                                                            className="text-accent fs-5 align-items-center"
                                                            to="/"
                                                      >
                                                            Back to Home
                                                            <i className="bx bx-right-arrow-alt ps-2"></i>
                                                      </Link>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default PageNotFound;
