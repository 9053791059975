import React from "react";
import { ProductContainer } from "./style";
import { CategoryTitle } from "./style";

const ProductImage = ({ image, category, title }) => {
      return (
            <>
                  <ProductContainer>
                        {image && <img src={image} />}
                        <div className={image && "ps-3"}>
                              <div>{title}</div>
                              {category && (
                                    <CategoryTitle>{category}</CategoryTitle>
                              )}
                        </div>
                  </ProductContainer>
            </>
      );
};

export default ProductImage;
