import React, { useState } from "react";
import { Link } from "react-router-dom";

import Offcanvas from 'react-bootstrap/Offcanvas';

const MobileMenu = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <>
                  <div className="downbar-mobile-navigation">
                        <div className="container-fluid">
                              <div className="row">
                                    <div className="col-md-12 align-items-center clearfix">
                                          <Link className='mobile-logo fs-5 card-animate float-start'>
                                                eSecurity Cart
                                          </Link>
                                          <Link onClick={handleShow} className='text-secondary mobile-navigation card-animate float-end'>
                                                <i className='bx bx-menu fs-3'></i>
                                          </Link>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>eSecurity Cart</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                              Some text as placeholder. In real life you can have the elements you
                              have chosen. Like, text, images, lists, etc.
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      )
}

export default MobileMenu;