import React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const SummaryCard = ({ detail }) => {
      return (
            <>
                  <div className="page-summary">
                        <div className="page-summary__header">
                              <span className="page-summary__title">
                                    {detail.title}
                              </span>
                        </div>
                        <OverlayScrollbarsComponent
                              element="div"
                              className="page-summary__body"
                              options={{ scrollbars: { autoHide: "scroll" } }}
                              defer
                        >
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: detail.description,
                                    }}
                              ></div>
                        </OverlayScrollbarsComponent>
                  </div>
            </>
      );
};

export default SummaryCard;
