import React from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const ProductTable = ({ products, handleProductPrice }) => {
      return (
            <>
                  <Table
                        bordered
                        className=" table-responsive table-hover align-items-center"
                  >
                        <thead>
                              <tr>
                                    <th style={{ width: "80px" }}>S.N</th>
                                    <th>Product Name</th>
                                    <th style={{ width: "160px" }}>Category</th>
                                    <th style={{ width: "120px" }}>
                                          MRP Price
                                    </th>
                                    <th style={{ width: "90px" }}>Stock</th>
                                    <th style={{ width: "90px" }}>Status</th>
                                    <th style={{ width: "100px" }}>Action</th>
                              </tr>
                        </thead>
                        <tbody>
                              {products.length > 0 ? (
                                    products.map((product, index) => (
                                          <tr key={index}>
                                                <td>{++index}</td>
                                                <td>
                                                      <div className="d-flex">
                                                            <span>
                                                                  <div className="d-flex align-items-center">
                                                                        {product.image && (
                                                                              <div className="flex-shrink-0 me-3">
                                                                                    <div className="avatar-sm bg-light rounded p-1 h-100">
                                                                                          <img
                                                                                                src={
                                                                                                      product.image
                                                                                                }
                                                                                                alt={
                                                                                                      product.name
                                                                                                }
                                                                                                className="img-fluid d-block"
                                                                                          />
                                                                                    </div>
                                                                              </div>
                                                                        )}
                                                                        <div className="flex-grow-2">
                                                                              <h5 className="fs-6 mb-1">
                                                                                    <a
                                                                                          href={`https://esecuritycart.com/products/${product.slug}`}
                                                                                          className="text-dark"
                                                                                          target="_blank"
                                                                                    >
                                                                                          {
                                                                                                product.title
                                                                                          }
                                                                                    </a>
                                                                              </h5>
                                                                              <p className="text-muted mb-0 fs-6">
                                                                                    Brand
                                                                                    :{" "}
                                                                                    <span className="fw-medium pe-3">
                                                                                          {
                                                                                                product.brand
                                                                                          }
                                                                                    </span>
                                                                              </p>
                                                                        </div>
                                                                  </div>
                                                            </span>
                                                      </div>
                                                </td>
                                                <td>
                                                      <div>
                                                            <div>
                                                                  {
                                                                        product.category
                                                                  }
                                                            </div>
                                                            <div className="text-muted">
                                                                  SKU:{" "}
                                                                  {
                                                                        product.product_code
                                                                  }
                                                            </div>
                                                      </div>
                                                </td>
                                                <td>{product.price}</td>
                                                <td>{product.quantity}</td>
                                                <td>
                                                      <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            defaultChecked={
                                                                  product.checked
                                                            }
                                                            onBlur={(event) =>
                                                                  handleProductPrice(
                                                                        event
                                                                              .target
                                                                              .value,
                                                                        product,
                                                                        "publish"
                                                                  )
                                                            }
                                                      />
                                                </td>
                                                <td style={{ width: "100px" }}>
                                                      {/* <Link
                                                            to={`/products/${product.slug}/edit`}
                                                      >
                                                            <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 20.547 20.299"
                                                                  fill="currentColor"
                                                                  width="14"
                                                                  className="table__icon"
                                                            >
                                                                  <g
                                                                        stroke="currentColor"
                                                                        strokeWidth=".4"
                                                                  >
                                                                        <path
                                                                              data-name="Path 78"
                                                                              d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                                                        ></path>
                                                                        <path
                                                                              data-name="Path 79"
                                                                              d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                                                        ></path>
                                                                  </g>
                                                            </svg>
                                                      </Link> */}
                                                      <button
                                                            className="btn btn-link text-danger p-0 ps-3"
                                                            style={{
                                                                  marginTop:
                                                                        "-5px",
                                                            }}
                                                      >
                                                            <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  className="table__icon"
                                                                  viewBox="0 0 19.4 22.169"
                                                                  fill="currentColor"
                                                                  width="16"
                                                            >
                                                                  <g
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.4"
                                                                  >
                                                                        <path
                                                                              data-name="Rectangle 2"
                                                                              d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                                                        ></path>
                                                                        <path
                                                                              data-name="Line 1"
                                                                              d="M.7 3.469h18"
                                                                        ></path>
                                                                        <path
                                                                              data-name="Path 77"
                                                                              d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                                                        ></path>
                                                                        <path
                                                                              data-name="Line 2"
                                                                              d="M7.623 6.238V18.7"
                                                                        ></path>
                                                                        <path
                                                                              data-name="Line 3"
                                                                              d="M11.777 6.238V18.7"
                                                                        ></path>
                                                                  </g>
                                                            </svg>
                                                      </button>
                                                </td>
                                          </tr>
                                    ))
                              ) : (
                                    <tr className="text-center">
                                          <td colSpan={9}>No product found</td>
                                    </tr>
                              )}
                        </tbody>
                  </Table>
            </>
      );
};

export default ProductTable;
