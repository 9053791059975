import React from "react";

const FormTitle = ({ title, summary }) => {
      return (
            <>
                  <div className="form__group-header">
                        <h4 className="form__group-title">{title}</h4>
                        <p className="fomr__group-summary">{summary}</p>
                  </div>
            </>
      )
}

export default FormTitle;