import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import UserContext from "../../stores/UserContext";
import { Card, Col, Row, Table } from "react-bootstrap";
import { CardBody, CardHeader, LinkButton } from "../../components/commons";
import { Navigate } from "react-router-dom";

const SupportTicket = () => {
      const [supports, setSupports] = useState([]);
      const userCtx = useContext(UserContext);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/support_tickets`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSupports(response.data.supports);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const [slug, setSlug] = useState(false);

      if (slug) {
            return <Navigate to={`/support-tickets/${slug}/show`} />;
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <Row>
                                                <Col md={12}>
                                                      <Card>
                                                            <CardHeader title="Support Ticket">
                                                                  <LinkButton
                                                                        link="/support-tickets/create"
                                                                        title="Open Ticket"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Table
                                                                        bordered
                                                                        className="table-responsive table-hover align-items-center"
                                                                  >
                                                                        <thead>
                                                                              <tr>
                                                                                    <th>
                                                                                          S.N
                                                                                    </th>
                                                                                    <th>
                                                                                          Department
                                                                                    </th>
                                                                                    <th>
                                                                                          Subject
                                                                                    </th>
                                                                                    <th>
                                                                                          Priority
                                                                                    </th>
                                                                                    <th>
                                                                                          Status
                                                                                    </th>
                                                                                    <th>
                                                                                          Date
                                                                                    </th>
                                                                                    {/* <th>Action</th> */}
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {supports.length >
                                                                              0 ? (
                                                                                    supports.map(
                                                                                          (
                                                                                                support,
                                                                                                index
                                                                                          ) => (
                                                                                                <tr
                                                                                                      key={
                                                                                                            index
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            setSlug(
                                                                                                                  support.slug
                                                                                                            )
                                                                                                      }
                                                                                                      style={{
                                                                                                            cursor: "pointer",
                                                                                                      }}
                                                                                                >
                                                                                                      <td>
                                                                                                            {
                                                                                                                  support.support_no
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  support.department
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  support.subject
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  support.priority
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  support.status
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  support.date
                                                                                                            }
                                                                                                      </td>
                                                                                                      {/* <td style={{ width: '100px' }}>
                                                                                          <Link className="text-secondary" to={`/support-tickets/${support.slug}/show`}>
                                                                                                <i className="bx bx-show fs-3"></i>
                                                                                          </Link>
                                                                                    </td> */}
                                                                                                </tr>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <tr className="text-center">
                                                                                          <td
                                                                                                colSpan={
                                                                                                      6
                                                                                                }
                                                                                          >
                                                                                                No
                                                                                                support
                                                                                                ticket
                                                                                                found
                                                                                          </td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </Table>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                          </Row>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default SupportTicket;
