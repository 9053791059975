import { Col } from "react-bootstrap";
import styled from "styled-components";

export const QuotationItems = styled(Col)`
      tr {
            th {
                  padding: 10px;
            }
      }

      table {
            tbody {
                  tr {
                        img {
                              height: 80px;
                              width: 80px;
                              object-fit: contain;
                        }

                        td {
                              padding: 10px;
                        }

                        button {
                              border-radius: 0;
                              padding: 4px 10px;
                              margin-right: 10px;
                              display: flex;
                              border: 0;
                              background: transparent;
                        }
                  }
            }
      }
`;

export const QuotationButton = styled.button`
      border-radius: 0;
      padding: 4px 16px;
      margin-right: 10px;
      border: 0;
      fill: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.white};

      &:hover {
            svg {
                  fill: ${(props) => props.theme.secondaryColor} !important;
            }
      }
`;
