import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";

import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import Pagination from "react-js-pagination";
import ProductTable from "../../components/product/ProductTable";

import Progress from "react-progress-2";
import ProductDashboard from "../../components/product/ProductDashboard";
import UserContext from "../../stores/UserContext";
import { Card, Col, Container, Row } from "react-bootstrap";
import { CardBody, CardHeader, LinkButton } from "../../components/commons";

const ProductList = () => {
      const userCtx = useContext(UserContext);

      const [products, setProducts] = useState([]);
      const [loading, setLoading] = useState(false);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [totalProducts, setTotalProducts] = useState(0);
      const [pageNumber, setPageNumber] = useState(1);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                              setLoading(false);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.errorMessage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      async function handleProductPrice(price, product, table) {
            const data = {
                  slug: product.slug,
                  price: price,
                  table: table,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      useEffect(() => {
            getProductData(pageNumber);
      }, [pageNumber]);

      async function getProductData(pageNumber) {
            setLoading(true);
            window.scrollTo(0, 0);
            Progress.show();
            setLoading(true);

            const url = `${process.env.REACT_APP_SECRET_KEY}/api/seller/products?page=${pageNumber}`;
            await axios
                  .get(url, {
                        headers: {
                              Authorization: `Bearer ${userCtx?.token}`,
                        },
                  })
                  .then((response) => {
                        setLoading(false);
                        setProducts(response.data.products);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
            Progress.hide();
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <Container fluid>
                                          <Row>
                                                <Col md={12} className="mb-4">
                                                      <ProductDashboard
                                                            loading={loading}
                                                            totalProducts={
                                                                  totalProducts
                                                            }
                                                      />
                                                </Col>
                                                <div className="col-md-12">
                                                      <Card>
                                                            <CardHeader title="Product List">
                                                                  <LinkButton
                                                                        link="/products/manage"
                                                                        title="Manage Product"
                                                                  />
                                                                  <LinkButton
                                                                        link="/products/manage/price"
                                                                        title="Manage Price"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <ProductTable
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        products={
                                                                              products
                                                                        }
                                                                        handleProductPrice={
                                                                              handleProductPrice
                                                                        }
                                                                  />
                                                            </CardBody>
                                                      </Card>
                                                </div>
                                                <div className="col-md-12">
                                                      {totalProducts > 10 && (
                                                            <Pagination
                                                                  activePage={
                                                                        currentPage
                                                                  }
                                                                  totalItemsCount={
                                                                        totalProducts
                                                                  }
                                                                  itemsCountPerPage={
                                                                        perPages
                                                                  }
                                                                  onChange={(
                                                                        value
                                                                  ) =>
                                                                        setPageNumber(
                                                                              value
                                                                        )
                                                                  }
                                                                  itemClass="page-item"
                                                                  linkClass="page-link"
                                                                  firstPageText="First"
                                                                  lastPageText="Last"
                                                            />
                                                      )}
                                                </div>
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductList;
