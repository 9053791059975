import axios from "axios";
import React, { useContext, useState } from "react";

import $ from "jquery";
import { Form } from "react-bootstrap";
import Sidebar from "../../components/dashboard/Sidebar";
import FormInput from "../../components/form/FormInput";
import ProfileNavigation from "../../components/profile/ProfileNavigation";
import { toast } from "react-hot-toast";
import UserContext from "../../stores/UserContext";
import { FormButton } from "../../components/form";

const ChangePassword = () => {
      const userCtx = useContext(UserContext);

      const [oldPassword, setOldPassword] = useState("");
      const [password, setPassword] = useState("");
      const [confirmPassword, setConfirmPassword] = useState("");

      const [isLoading, setIsLoading] = useState(false);

      async function handleFormSubmit(event) {
            setIsLoading(true);
            event.preventDefault();

            const data = {
                  oldpassword: oldPassword,
                  password: password,
                  password_confirmation: confirmPassword,
            };
            $("button[type=submit]").prop("disabled", true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/change-password`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result == "success") {
                                    $("form").trigger("reset");
                                    toast.success(response.data.message);
                              }

                              if (response.data.result == "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (
                                                response.data.message
                                                      .oldpassword
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .oldpassword
                                                );
                                          }
                                          if (response.data.message.password) {
                                                toast.error(
                                                      response.data.message
                                                            .password
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .password_confirmation
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .password_confirmation
                                                );
                                          }
                                    }
                              }
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setIsLoading(false);
                              toast.error(error.message);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard d-flex mb-0">
                                    <ProfileNavigation />

                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">
                                                Change Account Password
                                          </h5>
                                          <Form onSubmit={handleFormSubmit}>
                                                <FormInput
                                                      label="Current Paassword"
                                                      required={true}
                                                      type="password"
                                                      handleInput={
                                                            setOldPassword
                                                      }
                                                      classes="mb-3"
                                                />
                                                <FormInput
                                                      label="New Paassword"
                                                      required={true}
                                                      type="password"
                                                      handleInput={setPassword}
                                                      classes="mb-3"
                                                />
                                                <FormInput
                                                      label="Confirm Paassword"
                                                      required={true}
                                                      type="password"
                                                      handleInput={
                                                            setConfirmPassword
                                                      }
                                                      classes="mb-3"
                                                />
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ChangePassword;
