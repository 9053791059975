import React, { useState } from "react";
import Sidebar from "../../components/dashboard/Sidebar";

const OrderList = () => {
      const [orders, setOrders] = useState([]);
      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div class="product__content bg-white">
                                                            <div class="product__content-item">
                                                                  <div class="product__content-item__header">
                                                                        <h1 class="product__content-item__title mb-0">
                                                                              Order
                                                                              List
                                                                        </h1>
                                                                  </div>
                                                                  <div class="product__content-item__body">
                                                                        <div class="w-100 d-flex align-items-center">
                                                                              <form
                                                                                    novalidate=""
                                                                                    role="search"
                                                                                    class="w-100 d-flex align-items-center position-relative"
                                                                              >
                                                                                    <label
                                                                                          for="search"
                                                                                          class="product__content-item__label"
                                                                                    >
                                                                                          Search
                                                                                    </label>
                                                                                    <button class="product__content-item__btn">
                                                                                          <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                fill="none"
                                                                                                viewBox="0 0 24 24"
                                                                                                stroke="currentColor"
                                                                                                class="w-5 h-5"
                                                                                          >
                                                                                                <path
                                                                                                      stroke-linecap="round"
                                                                                                      stroke-linejoin="round"
                                                                                                      stroke-width="2"
                                                                                                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                                                ></path>
                                                                                          </svg>
                                                                                    </button>
                                                                                    <input
                                                                                          type="text"
                                                                                          id="search"
                                                                                          name="searchText"
                                                                                          class="product__content-item__input"
                                                                                          placeholder="Type your query and press enter"
                                                                                          aria-label="Search"
                                                                                          autocomplete="off"
                                                                                    />
                                                                              </form>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="col-md-12">
                                                      <div className="bg-white pb-1">
                                                            <table className="table table-bordered table-responsive table-hover align-items-center">
                                                                  <thead>
                                                                        <tr>
                                                                              <td>
                                                                                    Image
                                                                              </td>
                                                                              <td>
                                                                                    Title
                                                                              </td>
                                                                              <td>
                                                                                    Ordered
                                                                                    Date
                                                                              </td>
                                                                              <td>
                                                                                    Qty
                                                                              </td>
                                                                              <td>
                                                                                    Status
                                                                              </td>
                                                                              <td>
                                                                                    Action
                                                                              </td>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        {orders.length >
                                                                        0 ? (
                                                                              orders.map(
                                                                                    (
                                                                                          order,
                                                                                          index
                                                                                    ) => (
                                                                                          <tr
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                          >
                                                                                                <td>
                                                                                                      <img
                                                                                                            src="/images/download.jpg"
                                                                                                            style={{
                                                                                                                  height: "42px",
                                                                                                                  width: "42px",
                                                                                                            }}
                                                                                                            className="rounded"
                                                                                                      />
                                                                                                </td>
                                                                                                <td className="ellipsis">
                                                                                                      {
                                                                                                            order.title
                                                                                                      }
                                                                                                </td>
                                                                                                <td>
                                                                                                      {
                                                                                                            order.date
                                                                                                      }
                                                                                                </td>
                                                                                                <td className="text-center">
                                                                                                      {
                                                                                                            order.quantity
                                                                                                      }
                                                                                                </td>
                                                                                                <td className="text-center">
                                                                                                      {order.status
                                                                                                            ? "published"
                                                                                                            : "unpublished"}
                                                                                                </td>
                                                                                                <td>
                                                                                                      <button
                                                                                                            className="btn btn-link text-danger p-0 pe-3"
                                                                                                            style={{
                                                                                                                  marginTop:
                                                                                                                        "-5px",
                                                                                                            }}
                                                                                                      >
                                                                                                            <svg
                                                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                                                  className="table__icon"
                                                                                                                  viewBox="0 0 19.4 22.169"
                                                                                                                  fill="currentColor"
                                                                                                                  width="16"
                                                                                                            >
                                                                                                                  <g
                                                                                                                        fill="none"
                                                                                                                        stroke="currentColor"
                                                                                                                        stroke-linecap="round"
                                                                                                                        stroke-linejoin="round"
                                                                                                                        stroke-width="1.4"
                                                                                                                  >
                                                                                                                        <path
                                                                                                                              data-name="Rectangle 2"
                                                                                                                              d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                              data-name="Line 1"
                                                                                                                              d="M.7 3.469h18"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                              data-name="Path 77"
                                                                                                                              d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                              data-name="Line 2"
                                                                                                                              d="M7.623 6.238V18.7"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                              data-name="Line 3"
                                                                                                                              d="M11.777 6.238V18.7"
                                                                                                                        ></path>
                                                                                                                  </g>
                                                                                                            </svg>
                                                                                                      </button>
                                                                                                      <a href="#">
                                                                                                            <svg
                                                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                                                  viewBox="0 0 20.547 20.299"
                                                                                                                  fill="currentColor"
                                                                                                                  width="14"
                                                                                                                  className="table__icon"
                                                                                                            >
                                                                                                                  <g
                                                                                                                        stroke="currentColor"
                                                                                                                        stroke-width=".4"
                                                                                                                  >
                                                                                                                        <path
                                                                                                                              data-name="Path 78"
                                                                                                                              d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                                                                                                        ></path>
                                                                                                                        <path
                                                                                                                              data-name="Path 79"
                                                                                                                              d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                                                                                                        ></path>
                                                                                                                  </g>
                                                                                                            </svg>
                                                                                                      </a>
                                                                                                </td>
                                                                                          </tr>
                                                                                    )
                                                                              )
                                                                        ) : (
                                                                              <tr className="text-center">
                                                                                    <td
                                                                                          colSpan={
                                                                                                6
                                                                                          }
                                                                                    >
                                                                                          No
                                                                                          order
                                                                                          found
                                                                                    </td>
                                                                              </tr>
                                                                        )}
                                                                  </tbody>
                                                            </table>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default OrderList;
