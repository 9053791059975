import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/dashboard/Sidebar";

import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import FormInput from "../../components/form/FormInput";
import FormTitle from "../../components/form/FormTitle";
import FormSelect from "../../components/form/FormSelect";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserContext from "../../stores/UserContext";

const PromotionEdit = () => {
      const userCtx = useContext(UserContext);

      const { promotionId } = useParams();
      const [imageUrl, setImageUrl] = useState("");
      const user = JSON.parse(localStorage.getItem("userData"));
      const [promotion, setPromotion] = useState([]);

      const [brands, setBrands] = useState([]);
      const [startDate, setStartDate] = useState(new Date());

      const [image, setImage] = useState("");
      const [brand, setBrand] = useState("");
      const [title, setTitle] = useState("");
      const [expiredDate, setExpiredDate] = useState("");
      const [startedDate, setStartedDate] = useState("");
      const [description, setDescription] = useState("");

      const handleImage = (file) => {
            setImage(file);
            setImageUrl(URL.createObjectURL(file));
      };

      async function handleCategories() {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brands`,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands(response.data.brands);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/promotions/${promotionId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setPromotion(response.data.promotion);
                              setTitle(response.data.promotion.title);
                              setBrand(response.data.promotion.brand);
                              setImageUrl(response.data.promotion.image);
                              setDescription(
                                    response.data.promotion.description
                              );
                              setExpiredDate(
                                    new Date(
                                          response.data.promotion.expired_date
                                    )
                              );
                              setStartedDate(
                                    new Date(
                                          response.data.promotion.started_date
                                    )
                              );
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      useEffect(() => {
            handleCategories();
      }, [promotionId]);

      async function handleFormSubmit(event) {
            event.preventDefault();
            const data = {
                  title: title,
                  brand: brand,
                  started_date: startedDate,
                  expired_date: expiredDate,
                  description: description,
                  image: image,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/promotions/${promotionId}/update`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setImageUrl("");
                        }
                        if (response.data.result === "error") {
                              if (response.data.message) {
                                    if (response.data.message.title) {
                                          toast.error(
                                                response.data.message.title
                                          );
                                    }
                                    if (response.data.message.brand) {
                                          toast.error(
                                                response.data.message.brand
                                          );
                                    }
                                    if (response.data.message.started_date) {
                                          toast.error(
                                                response.data.message
                                                      .started_date
                                          );
                                    }
                                    if (response.data.message.expired_date) {
                                          toast.error(
                                                response.data.message
                                                      .expired_date
                                          );
                                    }
                                    if (response.data.message.description) {
                                          toast.error(
                                                response.data.message
                                                      .description
                                          );
                                    }
                                    if (response.data.message.image) {
                                          toast.error(
                                                response.data.message.image
                                          );
                                    }
                              }
                              if (response.data.errorMessage) {
                                    toast.error(response.data.errorMessage);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="form__header">
                                                            <h1 className="form__header-title">
                                                                  EditPromotion
                                                            </h1>
                                                      </div>
                                                      <form
                                                            onSubmit={
                                                                  handleFormSubmit
                                                            }
                                                      >
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Featured Image"
                                                                        summary="Upload your promotion featured image here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <section className="position-relative">
                                                                              <div
                                                                                    role="button"
                                                                                    tabIndex="0"
                                                                                    className="form__group-upload"
                                                                              >
                                                                                    <input
                                                                                          accept="image/*"
                                                                                          onChange={(
                                                                                                event
                                                                                          ) =>
                                                                                                handleImage(
                                                                                                      event
                                                                                                            .target
                                                                                                            .files[0]
                                                                                                )
                                                                                          }
                                                                                          type="file"
                                                                                          autoComplete="off"
                                                                                          tabIndex="-1"
                                                                                          className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                                                    />
                                                                                    <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          width="41px"
                                                                                          height="30px"
                                                                                          viewBox="0 0 40.909 30"
                                                                                          className="text-muted-light"
                                                                                    >
                                                                                          <g transform="translate(0 -73.091)">
                                                                                                <path
                                                                                                      data-name="Path 2125"
                                                                                                      d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                                                      transform="translate(0)"
                                                                                                      fill="currentColor"
                                                                                                ></path>
                                                                                          </g>
                                                                                    </svg>
                                                                                    <p className="form__group-description">
                                                                                          <span className="text-accent font-semibold">
                                                                                                Upload
                                                                                                an
                                                                                                image
                                                                                          </span>
                                                                                          or
                                                                                          drag
                                                                                          and
                                                                                          drop{" "}
                                                                                          <br />
                                                                                          <span className="form__group-drag">
                                                                                                PNG,
                                                                                                JPG
                                                                                          </span>
                                                                                    </p>
                                                                              </div>
                                                                        </section>
                                                                        {imageUrl && (
                                                                              <img
                                                                                    src={
                                                                                          imageUrl
                                                                                    }
                                                                                    className="rounded mt-3 border p-1"
                                                                                    style={{
                                                                                          height: "60px",
                                                                                          width: "60px",
                                                                                    }}
                                                                              />
                                                                        )}
                                                                  </div>
                                                            </div>
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Description"
                                                                        summary="Add your promotion description and necessary information from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormInput
                                                                              label="Title"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    promotion.title
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setTitle
                                                                              }
                                                                        />
                                                                        <div className="mb-4">
                                                                              <label className="form__group-label">
                                                                                    Description
                                                                              </label>
                                                                              <textarea
                                                                                    defaultValue={
                                                                                          promotion.description
                                                                                    }
                                                                                    className="form__group-message"
                                                                                    autoComplete="off"
                                                                                    autoCorrect="off"
                                                                                    autoCapitalize="off"
                                                                                    spellCheck="false"
                                                                                    rows="4"
                                                                                    onChange={
                                                                                          setDescription
                                                                                    }
                                                                              ></textarea>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Brand"
                                                                        summary="Select brand & promotion running duration from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormSelect
                                                                              classes="mb-3"
                                                                              value={
                                                                                    promotion.brand
                                                                              }
                                                                              label="Brand"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleSelect={
                                                                                    setBrand
                                                                              }
                                                                              data={
                                                                                    brands
                                                                              }
                                                                        />
                                                                        <div className="mb-3">
                                                                              <label className="form__group-label">
                                                                                    Started
                                                                                    Date{" "}
                                                                                    <span className="text-danger">
                                                                                          *
                                                                                    </span>{" "}
                                                                              </label>
                                                                              <ReactDatePicker
                                                                                    selected={
                                                                                          startedDate
                                                                                    }
                                                                                    className="form__group-input "
                                                                                    required={
                                                                                          true
                                                                                    }
                                                                                    onChange={
                                                                                          setStartedDate
                                                                                    }
                                                                              />
                                                                        </div>
                                                                        <div className="mb-4">
                                                                              <label className="form__group-label">
                                                                                    Expired
                                                                                    Date{" "}
                                                                                    <span className="text-danger">
                                                                                          *
                                                                                    </span>{" "}
                                                                              </label>
                                                                              <ReactDatePicker
                                                                                    label="Expired On"
                                                                                    className="form__group-input"
                                                                                    classes="mb-3"
                                                                                    selected={
                                                                                          expiredDate
                                                                                    }
                                                                                    required={
                                                                                          true
                                                                                    }
                                                                                    onChange={
                                                                                          setExpiredDate
                                                                                    }
                                                                              />
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="mb-4 text-end">
                                                                  <button
                                                                        data-variant="normal"
                                                                        className="form__group-btn bg-accent text-light"
                                                                        type="submit"
                                                                  >
                                                                        Update
                                                                        Promotion
                                                                  </button>
                                                            </div>
                                                      </form>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default PromotionEdit;
