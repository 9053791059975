import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";

import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import Pagination from "react-js-pagination";
import ProductTable from "../../components/product/ProductTable";

import Progress from "react-progress-2";
import ProductDashboard from "../../components/product/ProductDashboard";
import UserContext from "../../stores/UserContext";
import {
      Card,
      Col,
      Container,
      Form,
      Row,
      Spinner,
      Table,
} from "react-bootstrap";
import {
      CardBody,
      CardHeader,
      EditButton,
      LinkButton,
} from "../../components/commons";
import ViewButton from "../../components/commons/ViewButton";

const ProductRequestList = () => {
      const userCtx = useContext(UserContext);

      const [products, setProducts] = useState([]);
      const [loading, setLoading] = useState(false);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [totalProducts, setTotalProducts] = useState(0);
      const [pageNumber, setPageNumber] = useState(1);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/product_requests`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                              setLoading(false);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.errorMessage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            getProductData(pageNumber);
      }, [pageNumber]);

      async function getProductData(pageNumber) {
            setLoading(true);
            window.scrollTo(0, 0);
            Progress.show();
            setLoading(true);

            const url = `${process.env.REACT_APP_SECRET_KEY}/api/seller/product_requests?page=${pageNumber}`;
            await axios
                  .get(url, {
                        headers: {
                              Authorization: `Bearer ${userCtx?.token}`,
                        },
                  })
                  .then((response) => {
                        setLoading(false);
                        setProducts(response.data.products);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
            Progress.hide();
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <Container fluid>
                                          <Row>
                                                <div className="col-md-12">
                                                      <Card>
                                                            <CardHeader title="Requested Product">
                                                                  <LinkButton
                                                                        link="/product_requests/manage/price"
                                                                        title="Manage Price"
                                                                  />
                                                                  <LinkButton
                                                                        link="/products"
                                                                        title="Back"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Table
                                                                        bordered
                                                                        className=" table-responsive table-hover align-items-center"
                                                                  >
                                                                        <thead>
                                                                              <tr>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "80px",
                                                                                          }}
                                                                                    >
                                                                                          S.N
                                                                                    </th>
                                                                                    <th>
                                                                                          Product
                                                                                          Name
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "160px",
                                                                                          }}
                                                                                    >
                                                                                          Category
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "120px",
                                                                                          }}
                                                                                    >
                                                                                          MRP
                                                                                          Price
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "90px",
                                                                                          }}
                                                                                    >
                                                                                          Stock
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "90px",
                                                                                          }}
                                                                                    >
                                                                                          Status
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "100px",
                                                                                          }}
                                                                                    >
                                                                                          Action
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {products.length >
                                                                              0 ? (
                                                                                    products.map(
                                                                                          (
                                                                                                product,
                                                                                                index
                                                                                          ) => (
                                                                                                <tr
                                                                                                      key={
                                                                                                            index
                                                                                                      }
                                                                                                >
                                                                                                      <td>
                                                                                                            {
                                                                                                                  ++index
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div className="d-flex">
                                                                                                                  <span>
                                                                                                                        <div className="d-flex align-items-center">
                                                                                                                              {product.image && (
                                                                                                                                    <div className="flex-shrink-0 me-3">
                                                                                                                                          <div className="avatar-sm bg-light rounded p-1 h-100">
                                                                                                                                                <img
                                                                                                                                                      src={
                                                                                                                                                            product.image
                                                                                                                                                      }
                                                                                                                                                      alt={
                                                                                                                                                            product.name
                                                                                                                                                      }
                                                                                                                                                      className="img-fluid d-block"
                                                                                                                                                />
                                                                                                                                          </div>
                                                                                                                                    </div>
                                                                                                                              )}
                                                                                                                              <div className="flex-grow-2">
                                                                                                                                    <h5 className="fs-6 mb-1">
                                                                                                                                          {
                                                                                                                                                product.title
                                                                                                                                          }
                                                                                                                                    </h5>
                                                                                                                                    <p className="text-muted mb-0 fs-6">
                                                                                                                                          Brand
                                                                                                                                          :{" "}
                                                                                                                                          <span className="fw-medium pe-3">
                                                                                                                                                {
                                                                                                                                                      product.brand_name
                                                                                                                                                }
                                                                                                                                          </span>
                                                                                                                                    </p>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </span>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div>
                                                                                                                  <div>
                                                                                                                        {
                                                                                                                              product.category_name
                                                                                                                        }
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  product.mrp_price
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  product.quantity
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  product.status
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td
                                                                                                            style={{
                                                                                                                  width: "100px",
                                                                                                            }}
                                                                                                      >
                                                                                                            <ViewButton
                                                                                                                  classes="me-2"
                                                                                                                  link={`/product_requests/${product.slug}/show`}
                                                                                                            />
                                                                                                            <EditButton
                                                                                                                  link={`/product_requests/${product.slug}/edit`}
                                                                                                            />
                                                                                                      </td>
                                                                                                </tr>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <tr className="text-center">
                                                                                          <td
                                                                                                colSpan={
                                                                                                      9
                                                                                                }
                                                                                          >
                                                                                                No
                                                                                                product
                                                                                                found
                                                                                          </td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </Table>
                                                            </CardBody>
                                                      </Card>
                                                </div>
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductRequestList;
