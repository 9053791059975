import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import $ from "jquery";
import Sidebar from "../../components/dashboard/Sidebar";
import FormInput from "../../components/form/FormInput";
import ProfileNavigation from "../../components/profile/ProfileNavigation";
import UserContext from "../../stores/UserContext";
import { FormButton } from "../../components/form";

const InCharge = () => {
      const userCtx = useContext(UserContext);
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [contactNumber, setContactNumber] = useState("");
      const [position, setPosition] = useState("");
      const [alternativeName, setAlternativeName] = useState("");
      const [alternativeEmail, setAlternativeEmail] = useState("");
      const [alternativePosition, setAlternativePosition] = useState("");
      const [alternativePhone, setAlternativePhone] = useState("");

      const [isLoading, setIsLoading] = useState(false);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/extra/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setName(response.data.seller.contact_person_name);
                              setContactNumber(
                                    response.data.seller.contact_person_phone
                              );
                              setEmail(response.data.seller.person_first_email);
                              setPosition(
                                    response.data.seller.person_first_position
                              );
                              setAlternativeName(
                                    response.data.seller.person_second_name
                              );
                              setAlternativePhone(
                                    response.data.seller.person_second_phone
                              );
                              setAlternativeEmail(
                                    response.data.seller.person_second_email
                              );
                              setAlternativePosition(
                                    response.data.seller.person_second_position
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      async function handleFormSubmit(event) {
            setIsLoading(true);
            event.preventDefault();
            $("button[type=submit]").prop("disabled", true);

            const data = {
                  name: name,
                  email_address: email,
                  phone_number: contactNumber,
                  position: position,
                  alternative_name: alternativeName,
                  alternative_email: alternativeEmail,
                  alternative_phone: alternativePosition,
                  alternative_position: alternativePosition,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/extra/details`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (response.data.message.name) {
                                                toast.error(
                                                      response.data.message.name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .email_address
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .email_address
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .phone_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (response.data.message.position) {
                                                toast.error(
                                                      response.data.message
                                                            .position
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .alternative_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .alternative_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .alternative_email
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .alternative_email
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .alternative_phone
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .alternative_phone
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .alternative_position
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .alternative_position
                                                );
                                          }
                                    }
                              }

                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard d-flex mb-0">
                                    <ProfileNavigation />
                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">
                                                Person In Charge
                                          </h5>
                                          <Form
                                                onSubmit={handleFormSubmit}
                                                className="row g-3"
                                          >
                                                <FormInput
                                                      label="Person Name"
                                                      required={true}
                                                      type="text"
                                                      classes="col-md-6"
                                                      value={name}
                                                      handleInput={setName}
                                                />
                                                <FormInput
                                                      label="Person Position"
                                                      required={true}
                                                      type="text"
                                                      classes="col-md-6"
                                                      value={position}
                                                      handleInput={setPosition}
                                                />
                                                <FormInput
                                                      label="Person Contact No."
                                                      required={true}
                                                      type="text"
                                                      value={contactNumber}
                                                      classes="col-md-6"
                                                      handleInput={
                                                            setContactNumber
                                                      }
                                                />
                                                <FormInput
                                                      label="Person Email Address"
                                                      required={true}
                                                      classes="col-md-6"
                                                      type="email"
                                                      value={email}
                                                      handleInput={setEmail}
                                                />
                                                <div className="col-md-12">
                                                      <h5 className="mt-3">
                                                            Alternative Person
                                                            In Charge
                                                      </h5>
                                                </div>
                                                <FormInput
                                                      label="Person Name"
                                                      required={false}
                                                      classes="col-md-6"
                                                      type="text"
                                                      value={alternativeName}
                                                      handleInput={
                                                            setAlternativeName
                                                      }
                                                />
                                                <FormInput
                                                      label="Person Position"
                                                      required={false}
                                                      type="text"
                                                      classes="col-md-6"
                                                      value={
                                                            alternativePosition
                                                      }
                                                      handleInput={
                                                            setAlternativePosition
                                                      }
                                                />
                                                <FormInput
                                                      label="Person Contact No."
                                                      required={false}
                                                      classes="col-md-6"
                                                      type="text"
                                                      value={alternativePhone}
                                                      handleInput={
                                                            setAlternativePhone
                                                      }
                                                />
                                                <FormInput
                                                      label="Person Email Address"
                                                      required={false}
                                                      type="email"
                                                      classes="col-md-6"
                                                      value={alternativeEmail}
                                                      handleInput={
                                                            setAlternativeEmail
                                                      }
                                                />
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default InCharge;
