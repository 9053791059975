import React, { useEffect, useState } from "react";
import "../auth/style.css";

import axios from "axios";
import BrandList from "../components/auth/BrandList";
import LoginCard from "../components/auth/LoginCard";
import SummaryCard from "../components/auth/SummaryCard";
import { Link } from "react-router-dom";

const TermCondition = () => {
      const [detail, setDetail] = useState([]);

      useEffect(() => {
            const data = {
                  shop_code: 22280,
                  page_id: "terms-of-us",
            };
            axios.post(
                  `https://goworkbiz.infinityinfosys.com/api/pages/details`,
                  data
            ).then((response) => {
                  setDetail(response.data.page);
            });
      }, []);

      return (
            <>
                  <div className="page-ath">
                        <div className="page-ath-wrap">
                              <div className="page-ath-content">
                                    <div className="page-ath-header">
                                          <Link
                                                to="/"
                                                className="page-ath-logo"
                                          >
                                                <img
                                                      src="/images/logo.jpg"
                                                      className="w-100"
                                                      alt="Seller Center - eSecurity Cart"
                                                />
                                          </Link>
                                    </div>

                                    <LoginCard />
                                    <div className="page-ath-footer">
                                          <ul className="footer-links">
                                                <li>
                                                      ©4 Infinity Infosys Pvt.
                                                      Ltd.
                                                </li>
                                          </ul>
                                    </div>
                              </div>

                              <div className="page-ath-gfx">
                                    <div className="w-100 p-3">
                                          <div className="col-md-12">
                                                <SummaryCard
                                                      title="Terms & Condition"
                                                      detail={detail}
                                                />
                                                <BrandList />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default TermCondition;
