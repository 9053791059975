import React, { useState } from "react";

import $ from "jquery";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { SignupContainer } from "./style";

const SignUpCard = () => {
      const [firstName, setFirstName] = useState("");
      const [middleName, setMiddleName] = useState("");
      const [lastName, setLastName] = useState("");
      const [email, setEmail] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [contactPerson, setContactPerson] = useState("");
      const [companyName, setCompanyName] = useState("");
      const [companyAddress, setCompanyAddress] = useState("");
      const [pan, setPan] = useState("");
      const [panFile, setPanFile] = useState("");
      const [isLoading, setIsLoading] = useState(false);
      const [brand, setBrand] = useState("");
      const [authorizeBrand, setAuthorizeBrand] = useState("");

      async function handleSubmit(event) {
            event.preventDefault();

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  middle_name: middleName,
                  email: email,
                  pan: pan,
                  contact_person: contactPerson,
                  company_name: companyName,
                  company_address: companyAddress,
                  pan_file: panFile,
                  phone_number: phoneNumber,
                  brand: brand,
                  authorize_brand: authorizeBrand,
            };

            setIsLoading(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/sellers/request`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                                    $("form").trigger("reset");
                              }

                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (
                                                response.data.message.first_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .first_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .middle_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .middle_name
                                                );
                                          }
                                          if (response.data.message.last_name) {
                                                toast.error(
                                                      response.data.message
                                                            .last_name
                                                );
                                          }
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .phone_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (response.data.message.pan) {
                                                toast.error(
                                                      response.data.message.pan
                                                );
                                          }
                                          if (response.data.message.pan_file) {
                                                toast.error(
                                                      response.data.message
                                                            .pan_file
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .contact_person
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .contact_person
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .company_address
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .company_address
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .company_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .company_name
                                                );
                                          }
                                          if (response.data.message.brand) {
                                                toast.error(
                                                      response.data.message
                                                            .brand
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .authorize_brand
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .authorize_brand
                                                );
                                          }
                                    }
                              }
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              toast.error(error.message);
                        }, 3000);
                  });
      }

      return (
            <>
                  <SignupContainer>
                        <Form className="w-100" onSubmit={handleSubmit}>
                              <Row className="g-3">
                                    <Col lg={12}>
                                          <h4 className="mb-0 mt-2">
                                                Personal Details
                                          </h4>
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                First Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setFirstName(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>Middle Name</FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setMiddleName(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Last Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setLastName(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Email Address{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="email"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setEmail(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Phone No.{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                onChange={(event) =>
                                                      setPhoneNumber(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          <h4 className="mb-0 mt-2">
                                                Company Details
                                          </h4>
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Contact Person{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setContactPerson(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Company Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setCompanyName(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Company Address{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setCompanyAddress(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                PAN No{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setPan(event.target.value)
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                PAN Image{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="file"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setPanFile(
                                                            event.target
                                                                  .files[0]
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          <h4 className="mb-0 mt-2">
                                                Brand Details
                                          </h4>
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Brand Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="text"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setBrand(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>
                                                Brand's Authorize Photo{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="file"
                                                required={true}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                      setAuthorizeBrand(
                                                            event.target
                                                                  .files[0]
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          By clicking Send Request, I agree to
                                          eSecurity Cart's Terms of Use and
                                          Privacy Policy
                                    </Col>
                                    <Col lg={12}>
                                          <button
                                                className="btn btn-primary btn-block"
                                                style={{
                                                      backgroundColor:
                                                            "#e86b19",
                                                      borderColor: "#e86b19",
                                                }}
                                          >
                                                {isLoading ? (
                                                      <Spinner />
                                                ) : (
                                                      "Send Request"
                                                )}
                                          </button>
                                    </Col>
                              </Row>

                              <div>
                                    Already have account,{" "}
                                    <Link
                                          to="/"
                                          className="text-center mt-3 d-inline-block"
                                    >
                                          <span style={{ color: "#eb6a18" }}>
                                                Login here
                                          </span>
                                    </Link>
                              </div>
                        </Form>
                  </SignupContainer>
            </>
      );
};

export default SignUpCard;
