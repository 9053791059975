import React from "react";
import { LinkBox } from "./styles";
import { Spinner } from "react-bootstrap";

const LinkButton = ({ loading, link, title, color, handleClick, width }) => {
      return (
            <>
                  <LinkBox
                        to={link}
                        color={color}
                        role="button"
                        onClick={handleClick}
                        width={width}
                  >
                        {!loading ? title : <Spinner />}
                  </LinkBox>
            </>
      );
};

export default LinkButton;
