import React from "react";
import { Card, ListGroup, Table } from "react-bootstrap";
import { CardBody } from "reactstrap";
import Sidebar from "../../components/dashboard/Sidebar";
import { Header } from "../../layouts/Header";

const Help = () => {
      const user = JSON.parse(localStorage.getItem("userData"));

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <Header title="Help" />
                                                <div className="col-md-12"></div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Help;
