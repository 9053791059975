import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ title, icon, link, svg }) => {
      return (
            <>
                  <li className="side-by">
                        <NavLink to={link}>
                              {svg ? (
                                    svg
                              ) : (
                                    <i className={`${icon} icon`} aria-hidden="true"></i>
                              )}
                              <span className='title'>{title}</span>
                        </NavLink>
                  </li>
            </>
      )
}

export default MenuItem;