import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import FormInput from "../../components/form/FormInput";
import ProfileNavigation from "../../components/profile/ProfileNavigation";

import "./profile.css";
import UserContext from "../../stores/UserContext";
import { FormButton } from "../../components/form";

const ProfilePage = () => {
      const userCtx = useContext(UserContext);
      const [sellerName, setSellerName] = useState("");
      const [emailAddress, setEmailAddress] = useState("");
      const [mobileNumber, setMobileNumber] = useState("");
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/personal/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSellerName(response.data.seller.seller_name);
                              setEmailAddress(response.data.seller.email);
                              setMobileNumber(
                                    response.data.seller.phone_number
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const [isLoading, setIsLoading] = useState(false);

      async function handleFormSubmit(event) {
            setIsLoading(true);
            event.preventDefault();
            $("button[type=submit]").prop("disabled", true);

            const data = {
                  seller_name: sellerName,
                  email_address: emailAddress,
                  phone_number: mobileNumber,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/personal/details`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }
                              if (response.data.result == "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (
                                                response.data.message
                                                      .email_address
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .email_address
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .phone_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .seller_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .seller_name
                                                );
                                          }
                                    }
                              }
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard d-flex mb-0">
                                    <ProfileNavigation />

                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">
                                                Seller Account
                                          </h5>
                                          <Form onSubmit={handleFormSubmit}>
                                                <FormInput
                                                      label="Full Name"
                                                      type="text"
                                                      required={true}
                                                      classes="mb-3"
                                                      value={sellerName}
                                                      handleInput={
                                                            setSellerName
                                                      }
                                                />
                                                <FormInput
                                                      label="Contact Email address"
                                                      type="text"
                                                      required={true}
                                                      value={emailAddress}
                                                      classes="mb-3"
                                                      handleInput={
                                                            setEmailAddress
                                                      }
                                                />
                                                <FormInput
                                                      label="Contact Mobile Number"
                                                      type="text"
                                                      required={true}
                                                      value={mobileNumber}
                                                      classes="mb-3"
                                                      handleInput={
                                                            setMobileNumber
                                                      }
                                                />
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProfilePage;
