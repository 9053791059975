import React, { useContext, useEffect } from "react";
import "quill/dist/quill.snow.css";
import { useQuill } from "react-quilljs";
import UserContext from "../../../stores/UserContext";
import axios from "axios";
import toast from "react-hot-toast";

const FormTextarea = ({ url, setNotes, clearData, required }) => {
      const userCtx = useContext(UserContext);
      const theme = "snow";

      const modules = {
            toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  [{ align: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ size: ["small", false, "large", "huge"] }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["link", "image", "video"],
            ],
      };

      const placeholder = "Compose an epic...";

      const formats = [
            "bold",
            "italic",
            "underline",
            "strike",
            "image",
            "video",
            "link",
      ];

      const { quill, quillRef } = useQuill({
            theme,
            modules,
            formats,
            placeholder,
      });

      const insertToEditor = (url) => {
            const range = quill.getSelection();
            quill.insertEmbed(range.index, "image", url);
      };

      const saveToServer = async (file) => {
            const formData = new FormData();
            formData.append("file", file);

            try {
                  const response = await axios.post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/${
                              url || "seller/support_tickets/image/upload"
                        }`,
                        formData,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  );
                  insertToEditor(response.data.url); // Assuming the server responds with the URL of the uploaded image
            } catch (error) {
                  toast.error(error.message);
            }
      };

      const selectLocalImage = () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.onchange = () => {
                  const file = input.files[0];
                  if (file) {
                        saveToServer(file);
                  }
            };
      };

      useEffect(() => {
            if (quill) {
                  quill.on("text-change", () => {
                        setNotes(quill.root.innerHTML);
                  });
                  quill.getModule("toolbar").addHandler(
                        "image",
                        selectLocalImage
                  );
                  quill.clipboard.dangerouslyPasteHTML("");
            }
      }, [quill, clearData]);
      return (
            <>
                  <div className="mb-4">
                        <label className="form__group-label">
                              Description{" "}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}{" "}
                        </label>
                        <div
                              ref={quillRef}
                              style={{
                                    height: 220,
                              }}
                        />
                  </div>
            </>
      );
};

export default FormTextarea;
