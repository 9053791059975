import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../../components/dashboard/Sidebar";
import {
      Card,
      CardBody,
      Col,
      Container,
      FormControl,
      Row,
      Spinner,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-hot-toast";
import Progress from "react-progress-2";
import Pagination from "react-js-pagination";
import { Header } from "../../../layouts/Header";
import { FilterProduct } from "../../../components/frontend/products";
import {
      CategoryListFilter,
      CategoryListItem,
      CategoryListRemove,
      CategoryListRemoveIcon,
      ProductContainer,
      ProductDetail,
      ProductIcon,
      ProductImage,
      ProductPrice,
      ProductRow,
      ProductSummary,
      ProductTitle,
} from "../style";
import UserContext from "../../../stores/UserContext";

const ProductManage = () => {
      const userCtx = useContext(UserContext);
      const [customerQuotations, setCustomerQuotations] = useState([]);
      const [customerQuotationCount, setCustomerQuotationCount] = useState(0);
      const [loading, setLoading] = useState(true);

      const [brands, setBrands] = useState([]);
      const [categories, setCategories] = useState([]);

      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const [search, setSearch] = useState("");

      const [selectedBrands, setSelectedBrands] = useState([]);
      const [selectedBrandLists, setSelectedBrandLists] = useState([]);

      const [selectedCuisines, setSelectedCuisines] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/manage`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/manage/filter`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands(response.data.brands);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/manage/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCustomerQuotations(response.data.products);
                              setCustomerQuotationCount(
                                    response.data.products.length
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      function handleCustomerQuotation(product) {
            const productList = products;
            const checkProducts = productList.map((item) =>
                  item.id === product.id
                        ? { ...product, status: !item.status }
                        : item
            );
            setProducts(checkProducts);
      }

      const addQuotation = async (product, status) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/manage/${product.slug}/add`,
                        {
                              status: status,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              setCustomerQuotations(response.data.products);
                              setCustomerQuotationCount(
                                    response.data.products.length
                              );
                              handleCustomerQuotation(product);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === "category") {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === "subCategory") {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked,
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === "childCategory") {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked,
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory,
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };

      const handleChangeBrand = (id, type) => {
            const brandsStateList = brands;
            const changeCheckedBrand = brandsStateList.map((item) => {
                  if (type === "brand") {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setBrands(changeCheckedBrand);
      };

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();

            try {
                  const filters = [];

                  if (selectedBrands.length) {
                        filters.push(`brands=${selectedBrands.join(",")}`);
                  }
                  if (selectedCuisines.length) {
                        filters.push(
                              `categories=${selectedCuisines.join(",")}`
                        );
                  }

                  const filterQueryString =
                        filters.length > 0 ? `&${filters.join("&")}` : "";
                  const url = `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/manage?${filterQueryString}&search=${search}&page=${pageNumber}`;

                  const response = await axios.get(url, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem("token")
                              )}`,
                        },
                  });

                  setProducts(response.data.products);
                  setTotalProducts(response.data.totalProducts);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);

                  Progress.hide();
                  setLoading(false);
            } catch (error) {
                  toast.error(error.message);
                  Progress.hide();
                  setLoading(false);
            }
      }

      const handleSearch = async (event) => {
            setSearch(event.target.value);
      };

      const changePrice = async () => {
            const filters = [];

            const brandsChecked = brands
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: "brand",
                  }));

            const selectedBrandIds = brandsChecked.map((item) => item.id);
            setSelectedBrands(selectedBrandIds);
            setSelectedBrandLists(brandsChecked);

            if (selectedBrandIds.length) {
                  filters.push(`brands=${selectedBrandIds.join(",")}`);
            }

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: "category",
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: "subCategory",
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: "childCategory",
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked,
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCuisines(selectedCuisinesIds);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(",")}`);
            }

            if (search) {
                  filters.push(`search=${search}`);
            }

            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/seller/products/manage${
                  filters.length > 0 ? "?" + filters.join("&") : "?"
            }`;

            await axios
                  .get(apiUrl, {
                        headers: {
                              Authorization: `Bearer ${userCtx?.token}`,
                        },
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            changePrice();
      }, [brands, categories]);

      useEffect(() => {
            const delay = 500;

            const timer = setTimeout(() => {
                  changePrice();
            }, delay);

            return () => clearTimeout(timer);
      }, [search]);

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />

                        <div className="header">
                              <div className="downbar">
                                    <Container fluid>
                                          <Row>
                                                <Header
                                                      title="Product Manage"
                                                      link="/products"
                                                      search={false}
                                                      linkTitle="Back"
                                                />
                                                <Col md={12} className="mb-4">
                                                      <Card>
                                                            <CardBody>
                                                                  <FilterProduct
                                                                        brands={
                                                                              brands
                                                                        }
                                                                        handleBrandChange={
                                                                              handleChangeBrand
                                                                        }
                                                                        handleCategoryChange={
                                                                              handleChangeCategory
                                                                        }
                                                                        totalProducts={
                                                                              totalProducts
                                                                        }
                                                                        categories={
                                                                              categories
                                                                        }
                                                                        quotations={
                                                                              customerQuotations
                                                                        }
                                                                  />
                                                                  <div className="mt-3">
                                                                        <FormControl
                                                                              placeholder="Search by product name"
                                                                              value={
                                                                                    search
                                                                              }
                                                                              onChange={
                                                                                    handleSearch
                                                                              }
                                                                        />
                                                                  </div>

                                                                  <CategoryListFilter>
                                                                        {selectedBrandLists.length >
                                                                              0 &&
                                                                              selectedBrandLists.map(
                                                                                    (
                                                                                          brand,
                                                                                          index
                                                                                    ) => (
                                                                                          <li
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                          >
                                                                                                <CategoryListItem>
                                                                                                      {
                                                                                                            brand.title
                                                                                                      }
                                                                                                      <CategoryListRemove
                                                                                                            onClick={() =>
                                                                                                                  handleChangeBrand(
                                                                                                                        brand.id,
                                                                                                                        brand.type
                                                                                                                  )
                                                                                                            }
                                                                                                      >
                                                                                                            <CategoryListRemoveIcon>
                                                                                                                  <i className="bx bx-x"></i>
                                                                                                            </CategoryListRemoveIcon>
                                                                                                      </CategoryListRemove>
                                                                                                </CategoryListItem>
                                                                                          </li>
                                                                                    )
                                                                              )}
                                                                        {selectedCategories.length >
                                                                              0 &&
                                                                              selectedCategories.map(
                                                                                    (
                                                                                          item,
                                                                                          index
                                                                                    ) => (
                                                                                          <li
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                          >
                                                                                                <CategoryListItem>
                                                                                                      {
                                                                                                            item.title
                                                                                                      }
                                                                                                      <CategoryListRemove
                                                                                                            onClick={() =>
                                                                                                                  handleChangeCategory(
                                                                                                                        item.id,
                                                                                                                        item.type
                                                                                                                  )
                                                                                                            }
                                                                                                      >
                                                                                                            <CategoryListRemoveIcon>
                                                                                                                  <i className="bx bx-x"></i>
                                                                                                            </CategoryListRemoveIcon>
                                                                                                      </CategoryListRemove>
                                                                                                </CategoryListItem>
                                                                                          </li>
                                                                                    )
                                                                              )}
                                                                  </CategoryListFilter>
                                                                  <ProductRow className="g-4">
                                                                        {!loading ? (
                                                                              products.length >
                                                                              0 ? (
                                                                                    products.map(
                                                                                          (
                                                                                                product,
                                                                                                index
                                                                                          ) => (
                                                                                                <Col
                                                                                                      md={
                                                                                                            6
                                                                                                      }
                                                                                                      lg={
                                                                                                            3
                                                                                                      }
                                                                                                      sm={
                                                                                                            6
                                                                                                      }
                                                                                                      className="col-6"
                                                                                                >
                                                                                                      <ProductContainer
                                                                                                            key={
                                                                                                                  index
                                                                                                            }
                                                                                                      >
                                                                                                            <ProductImage>
                                                                                                                  <img
                                                                                                                        src={
                                                                                                                              product.image
                                                                                                                        }
                                                                                                                        alt={
                                                                                                                              product.title
                                                                                                                        }
                                                                                                                  />
                                                                                                            </ProductImage>
                                                                                                            <ProductDetail>
                                                                                                                  <ProductTitle>
                                                                                                                        {
                                                                                                                              product.title
                                                                                                                        }
                                                                                                                  </ProductTitle>
                                                                                                                  <ProductSummary>
                                                                                                                        <ProductPrice>
                                                                                                                              Brand:{" "}
                                                                                                                              {
                                                                                                                                    product.brand
                                                                                                                              }
                                                                                                                        </ProductPrice>
                                                                                                                        <ProductPrice>
                                                                                                                              Product
                                                                                                                              Code:{" "}
                                                                                                                              {
                                                                                                                                    product.product_code
                                                                                                                              }
                                                                                                                        </ProductPrice>
                                                                                                                        <ProductPrice>
                                                                                                                              Rs.{" "}
                                                                                                                              {
                                                                                                                                    product.price
                                                                                                                              }
                                                                                                                        </ProductPrice>
                                                                                                                  </ProductSummary>
                                                                                                            </ProductDetail>
                                                                                                            <ProductIcon>
                                                                                                                  <button
                                                                                                                        className={
                                                                                                                              product.status
                                                                                                                                    ? "added"
                                                                                                                                    : ""
                                                                                                                        }
                                                                                                                        onClick={() =>
                                                                                                                              addQuotation(
                                                                                                                                    product,
                                                                                                                                    product.status
                                                                                                                              )
                                                                                                                        }
                                                                                                                  >
                                                                                                                        {product.status ===
                                                                                                                        true
                                                                                                                              ? "Remove"
                                                                                                                              : "Add Product"}
                                                                                                                  </button>
                                                                                                            </ProductIcon>
                                                                                                      </ProductContainer>
                                                                                                </Col>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <>
                                                                                          No
                                                                                          Product
                                                                                          Found
                                                                                    </>
                                                                              )
                                                                        ) : (
                                                                              <>
                                                                                    <div className="text-center w-100">
                                                                                          <Spinner />
                                                                                    </div>
                                                                              </>
                                                                        )}
                                                                        {products.length >
                                                                              0 && (
                                                                              <Col
                                                                                    lg={
                                                                                          12
                                                                                    }
                                                                                    className="product__filter"
                                                                              >
                                                                                    <Pagination
                                                                                          activePage={
                                                                                                currentPage
                                                                                          }
                                                                                          totalItemsCount={
                                                                                                totalProducts
                                                                                          }
                                                                                          itemsCountPerPage={
                                                                                                perPages
                                                                                          }
                                                                                          onChange={(
                                                                                                pageNumber
                                                                                          ) =>
                                                                                                getData(
                                                                                                      pageNumber
                                                                                                )
                                                                                          }
                                                                                          itemClass="page-item"
                                                                                          linkClass="page-link"
                                                                                          firstPageText="First"
                                                                                          lastPageText="Last"
                                                                                    />
                                                                              </Col>
                                                                        )}
                                                                  </ProductRow>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductManage;
