import React, { useEffect, useState } from "react";
import { NavLink, Navigate, Link } from "react-router-dom";

import axios from "axios";
import "./register.css";
import SignUpCard from "../components/auth/SignUpCard";
import toast from "react-hot-toast";
import { Container } from "react-bootstrap";

const SignUp = () => {
      const [detail, setDetail] = useState([]);
      const [pageId, setPageId] = useState("seller-inquery");

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/${pageId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setDetail(response.data.page);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, [pageId]);

      const login = localStorage.getItem("isLoggedIn");
      if (login) {
            return <Navigate to="/dashboard" />;
      }

      return (
            <>
                  <div className="page-ath ">
                        <Container className="h-100">
                              <Container className="bg-white h-100 my-3 rounded">
                                    <div
                                          className="page-ath-header"
                                          style={{
                                                width: "320px",
                                                height: "auto",
                                          }}
                                    >
                                          <Link
                                                to="/"
                                                className="page-ath-logo"
                                          >
                                                <img
                                                      src="/images/logo.jpg"
                                                      className="w-100"
                                                      alt="Seller Center - eSecurity Cart"
                                                />
                                          </Link>
                                    </div>
                                    <SignUpCard />
                              </Container>
                        </Container>
                  </div>
            </>
      );
};

export default SignUp;
