import React, { useContext, useEffect, useState } from "react";
import DashboardCard from "../dashboard/DashboardCard";
import UserContext from "../../stores/UserContext";
import axios from "axios";
import toast from "react-hot-toast";

const ProductDashboard = () => {
      const userCtx = useContext(UserContext);
      const [totalProducts, setTotalProducts] = useState(0);
      const [pendingProducts, setPendingProducts] = useState(0);
      const [cancelledProducts, setCancelledProducts] = useState(0);
      const [rejectedProducts, setRejectedProducts] = useState(0);

      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/request/total`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTotalProducts(response.data.totalProduct);
                              setCancelledProducts(
                                    response.data.cancelledProduct
                              );
                              setPendingProducts(response.data.pendingProduct);
                              setRejectedProducts(
                                    response.data.rejectedProduct
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="row g-3">
                        <DashboardCard
                              title="Requested Product"
                              total={totalProducts}
                              icon="bx bx-briefcase-alt-2"
                              loading={loading}
                              url="/product_requests"
                        />
                        <DashboardCard
                              title="Pending Product"
                              total={pendingProducts}
                              url="/product_requests"
                              summary="View all"
                              icon="bx bx-time-five"
                              loading={loading}
                        />
                        <DashboardCard
                              title="Rejected Product"
                              total={rejectedProducts}
                              url="/product_requests"
                              summary="View all"
                              icon="bx bx-block"
                              loading={loading}
                        />
                        <DashboardCard
                              title="Cancelled Product"
                              total={cancelledProducts}
                              url="/product_requests"
                              summary="View all"
                              icon="bx bx-x"
                              loading={loading}
                        />
                  </div>
            </>
      );
};

export default ProductDashboard;
