import React from "react";
import { FormControl, FormLabel } from "react-bootstrap";

const FormInput = ({
      classes,
      label,
      required,
      type,
      handleInput,
      minValue,
      value,
      readOnlyValue,
}) => {
      return (
            <>
                  <div className={classes}>
                        <FormLabel>
                              {label}{" "}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </FormLabel>
                        <FormControl
                              type={type || "text"}
                              required={required}
                              onChange={(event) =>
                                    handleInput(event.target.value)
                              }
                              readOnly={readOnlyValue}
                              min={minValue}
                              defaultValue={value}
                        />
                  </div>
            </>
      );
};

export default FormInput;
