import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import FormFile from "../../components/form/FormFile";
import FormInput from "../../components/form/FormInput";
import ProfileNavigation from "../../components/profile/ProfileNavigation";
import UserContext from "../../stores/UserContext";
import { FormButton } from "../../components/form";

const BusinessInformation = () => {
      const userCtx = useContext(UserContext);

      const [ownerName, setOwnerName] = useState("");
      const [companyName, setCompanyName] = useState("");
      const [companyRegisterNo, setCompanyRegisterNo] = useState("");
      const [panNo, setPanNo] = useState("");
      const [phoneNo, setPhoneNo] = useState("");
      const [emailAddress, setEmailAddress] = useState("");
      const [landline, setLandline] = useState("");
      const [panFile, setPanFile] = useState("");
      const [authorizeFile, setAuthorizeFile] = useState("");
      const [authorizeFileUrl, setAuthorizeFileUrl] = useState("");
      const [otherFile, setOtherFile] = useState("");
      const [otherFileUrl, setOtherFileUrl] = useState("");
      const [imageUrl, setImageUrl] = useState("");

      const [isLoading, setIsLoading] = useState(false);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/business/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setOwnerName(response.data.seller.owner_name);
                              setCompanyName(response.data.seller.company_name);
                              setCompanyRegisterNo(
                                    response.data.seller.company_register
                              );
                              setImageUrl(response.data.seller.pan_files);
                              setPanNo(response.data.seller.pan_no);
                              setPhoneNo(response.data.seller.phone_number);
                              setEmailAddress(
                                    response.data.seller.email_address
                              );
                              setLandline(response.data.seller.landline);
                              setAuthorizeFileUrl(
                                    response.data.seller.authorize_letter
                              );
                              setOtherFileUrl(
                                    response.data.seller.other_documents
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      async function handleFormSubmit(event) {
            setIsLoading(true);
            event.preventDefault();

            const data = {
                  seller_name: ownerName,
                  company_name: companyName,
                  company_register: companyRegisterNo,
                  pan_no: panNo,
                  phone_number: phoneNo,
                  email_address: emailAddress,
                  landline: landline,
                  pan_files: panFile,
                  authorize_file: authorizeFile,
                  other_file: otherFile,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/business/details`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }

                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (
                                                response.data.message
                                                      .seller_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .seller_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .company_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .company_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .other_files
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .other_files
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .company_register
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .company_register
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .phone_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (response.data.message.landline) {
                                                toast.error(
                                                      response.data.message
                                                            .landline
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .email_address
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .email_address
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .authorize_file
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .authorize_file
                                                );
                                          }
                                          if (response.data.message.pan_files) {
                                                toast.error(
                                                      response.data.message
                                                            .pan_files
                                                );
                                          }
                                          if (response.data.message.pan_no) {
                                                toast.error(
                                                      response.data.message
                                                            .pan_no
                                                );
                                          }
                                    }
                              }
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
            setIsLoading(false);
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard d-flex mb-0">
                                    <ProfileNavigation />
                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">
                                                Business Information
                                          </h5>
                                          <Form
                                                className="row"
                                                onSubmit={handleFormSubmit}
                                          >
                                                <FormInput
                                                      label="Business Owner Name"
                                                      type="text"
                                                      required={true}
                                                      classes="col-md-12 mb-3"
                                                      value={ownerName}
                                                      handleInput={setOwnerName}
                                                />
                                                <FormInput
                                                      label="Company Name"
                                                      value={companyName}
                                                      type="text"
                                                      required={true}
                                                      classes="col-md-6 mb-3"
                                                      handleInput={
                                                            setCompanyName
                                                      }
                                                />
                                                <FormInput
                                                      label="Company Register No."
                                                      type="text"
                                                      value={companyRegisterNo}
                                                      classes="col-md-6 mb-3"
                                                      handleInput={
                                                            setCompanyRegisterNo
                                                      }
                                                />
                                                <FormInput
                                                      label="Company PAN No."
                                                      type="text"
                                                      required={true}
                                                      value={panNo}
                                                      classes="col-md-6 mb-3"
                                                      handleInput={setPanNo}
                                                />
                                                <FormInput
                                                      label="Phone No"
                                                      type="text"
                                                      required={true}
                                                      value={phoneNo}
                                                      handleInput={setPhoneNo}
                                                      classes="col-md-6 mb-3"
                                                />
                                                <FormInput
                                                      label="Email Address"
                                                      type="email"
                                                      required={true}
                                                      handleInput={
                                                            setEmailAddress
                                                      }
                                                      value={emailAddress}
                                                      classes="col-md-6 mb-3"
                                                />
                                                <FormInput
                                                      label="Landline"
                                                      type="text"
                                                      required={false}
                                                      value={landline}
                                                      classes="col-md-6 mb-3"
                                                      handleInput={setLandline}
                                                />
                                                <FormFile
                                                      classes="position-relative col-md-4"
                                                      handleImage={setPanFile}
                                                      imageUrl={imageUrl}
                                                      title="PAN / VAT Document"
                                                      required={true}
                                                      handleImageUrl={
                                                            setImageUrl
                                                      }
                                                />
                                                <FormFile
                                                      classes="position-relative col-md-4"
                                                      handleImage={
                                                            setAuthorizeFile
                                                      }
                                                      title="Authorize Letter Document"
                                                      required={true}
                                                      imageUrl={
                                                            authorizeFileUrl
                                                      }
                                                      handleImageUrl={
                                                            setAuthorizeFileUrl
                                                      }
                                                />
                                                <FormFile
                                                      classes="position-relative col-md-4"
                                                      handleImage={setOtherFile}
                                                      title="Other Document"
                                                      imageUrl={otherFileUrl}
                                                      handleImageUrl={
                                                            setOtherFileUrl
                                                      }
                                                />
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BusinessInformation;
