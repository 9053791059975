import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/dashboard/Sidebar";
import {
      Card,
      CardBody,
      Col,
      Container,
      Row,
      Spinner,
      Table,
} from "react-bootstrap";
import { Header } from "../../../layouts/Header";
import { QuotationItems } from "./style";
import axios from "axios";
import toast from "react-hot-toast";
import ProductImage from "../ProductImage";
import { DeleteIcon } from "../../../icons";

const ProductCheckout = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/manage/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const removeQuotation = () => {};
      return (
            <>
                  <div className="d-flex">
                        <Sidebar />

                        <div className="header">
                              <div className="downbar">
                                    <Container fluid>
                                          <Row>
                                                <Header
                                                      title="Product Manage"
                                                      link="/products"
                                                      search={false}
                                                      linkTitle="Back"
                                                />
                                                <Col md={12} className="mb-4">
                                                      <Card>
                                                            <CardBody>
                                                                  <Row>
                                                                        <Col
                                                                              lg={
                                                                                    12
                                                                              }
                                                                        >
                                                                              <Card>
                                                                                    <div className="row">
                                                                                          {!loading ? (
                                                                                                <QuotationItems
                                                                                                      lg={
                                                                                                            12
                                                                                                      }
                                                                                                >
                                                                                                      <Table
                                                                                                            bordered
                                                                                                            hover
                                                                                                            className="w-100"
                                                                                                      >
                                                                                                            <thead>
                                                                                                                  <tr>
                                                                                                                        <th className="text-center">
                                                                                                                              S.N
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                              Product
                                                                                                                              Item
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                              Brand
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                              Mrp
                                                                                                                              Price
                                                                                                                              (NRP)
                                                                                                                        </th>
                                                                                                                  </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                  {products?.length >
                                                                                                                  0 ? (
                                                                                                                        products?.map(
                                                                                                                              (
                                                                                                                                    item,
                                                                                                                                    index
                                                                                                                              ) => (
                                                                                                                                    <>
                                                                                                                                          <tr
                                                                                                                                                key={
                                                                                                                                                      index
                                                                                                                                                }
                                                                                                                                          >
                                                                                                                                                <td className="text-center">
                                                                                                                                                      {
                                                                                                                                                            ++index
                                                                                                                                                      }
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                      <div className="d-flex align-items-center">
                                                                                                                                                            <button
                                                                                                                                                                  type="button"
                                                                                                                                                                  onClick={() =>
                                                                                                                                                                        removeQuotation(
                                                                                                                                                                              item.slug
                                                                                                                                                                        )
                                                                                                                                                                  }
                                                                                                                                                            >
                                                                                                                                                                  <DeleteIcon color="red" />
                                                                                                                                                            </button>
                                                                                                                                                            <ProductImage
                                                                                                                                                                  title={
                                                                                                                                                                        item.product_name
                                                                                                                                                                  }
                                                                                                                                                                  image={
                                                                                                                                                                        item.image
                                                                                                                                                                  }
                                                                                                                                                            />
                                                                                                                                                      </div>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                      {
                                                                                                                                                            item.brand_name
                                                                                                                                                      }
                                                                                                                                                </td>
                                                                                                                                                <td className="text-center">
                                                                                                                                                      {
                                                                                                                                                            item.mrp_price
                                                                                                                                                      }
                                                                                                                                                </td>
                                                                                                                                          </tr>
                                                                                                                                    </>
                                                                                                                              )
                                                                                                                        )
                                                                                                                  ) : (
                                                                                                                        <>
                                                                                                                              <tr>
                                                                                                                                    <td
                                                                                                                                          colSpan={
                                                                                                                                                5
                                                                                                                                          }
                                                                                                                                          className="text-center"
                                                                                                                                    >
                                                                                                                                          No
                                                                                                                                          product
                                                                                                                                          found
                                                                                                                                    </td>
                                                                                                                              </tr>
                                                                                                                        </>
                                                                                                                  )}
                                                                                                            </tbody>
                                                                                                      </Table>
                                                                                                </QuotationItems>
                                                                                          ) : (
                                                                                                <>
                                                                                                      <div className="text-center w-100">
                                                                                                            <Spinner />
                                                                                                      </div>
                                                                                                </>
                                                                                          )}
                                                                                    </div>
                                                                              </Card>
                                                                        </Col>
                                                                  </Row>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductCheckout;
