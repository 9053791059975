import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";

import "overlayscrollbars/overlayscrollbars.css";

import PublicRouter from "../PublicRouter";
import Progress from "react-progress-2";
import "./style.css";
import "react-progress-2/main.css";
import { UserContextProvider } from "../stores";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/themes";

function App() {
      return (
            <UserContextProvider>
                  <ThemeProvider theme={lightTheme}>
                        <Router>
                              <Progress.Component
                                    style={{
                                          background: "#99999978",
                                          height: "2.5px",
                                    }}
                                    thumbStyle={{
                                          background: "rgb(232, 107, 25)",
                                          height: "2.5px",
                                    }}
                              />
                              <Toaster position="top-right" />
                              <Suspense fallback="Contents are loading">
                                    <PublicRouter />
                              </Suspense>
                        </Router>
                  </ThemeProvider>
            </UserContextProvider>
      );
}

export default App;
