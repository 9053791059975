import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const LinkBox = styled(Link).attrs(() => ({
      className: "btn btn-link",
}))`
      border-radius: 20px;
      border-width: 2px;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 18px;
      border-color: #e2e8f0;
      color: ${(props) => props.color || "#66615b"};
      transition: all 150ms linear;
      display: flex;
      align-items: center;
      width: ${(props) => props.width};
      justify-content: center;

      &:hover,
      &:focus,
      &:active {
            background-color: #e2e8f0;
            color: black;
            border-color: #66615b;
      }

      .spinner-border {
            width: 20px;
            height: 20px;
            border-width: 2px;
      }
`;
