import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Coupon } from "../../icons/navigations/Coupon";
import BottomNavigation from "../../layouts/BottomNavigation";
import MenuItem from "./MenuItem";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import UserContext from "../../stores/UserContext";

const Sidebar = () => {
      const userCtx = useContext(UserContext);
      const [navigate, setNavigate] = useState(false);
      const login = localStorage.getItem("isLoggedIn");

      function onLogoutHandler() {
            localStorage.clear();
            setNavigate(true);
      }

      useEffect(() => {
            if (login === null) {
                  onLogoutHandler();
            }
      }, []);

      // if (user) {
      //       if (user.email_verified_at === null) {
      //             return <Navigate to="/email/verify" />;
      //       }
      // }

      if (login === null) {
            return <Navigate to="/" />;
      }
      return (
            <>
                  {/* <div className="d-flex position-relative">
                        <div className="page-loader_page_loader__iOe6U"></div>
                        <h3 className="text-sm font-semibold text-body italic absolute top-1/2 -mt-2 w-full text-center">Loading...</h3>

                  </div> */}
                  <div className="sidebar-container bg-white border-end">
                        <div className="w-100 justify-content-center text-center mb-5">
                              <a href="#" className="logo w-100">
                                    eSecurity Cart
                              </a>
                        </div>
                        {/* <div
                              className="w-100 justify-content-center text-center px-2 overflow-hidden"
                              style={{
                                    height: "90px",
                                    textOverflow: "ellipsis",
                              }}
                        > */}
                        {/* {userCtx?.user ? (
                                    userCtx?.user.logo ? (
                                          <img
                                                src={`https://goworkbiz.infinityinfosys.com/uploads/${userCtx?.user.logo}`}
                                                className="rounded-circle me-2 border"
                                                style={{
                                                      height: "30px",
                                                      width: "30px",
                                                }}
                                          />
                                    ) : (
                                          <img
                                                src="/profile.png"
                                                className="rounded-circle me-2"
                                                style={{
                                                      height: "30px",
                                                      width: "30px",
                                                }}
                                          />
                                    )
                              ) : (
                                    <img
                                          src="/profile.png"
                                          className="rounded-circle me-2"
                                          style={{
                                                height: "30px",
                                                width: "30px",
                                          }}
                                    />
                              )} */}
                        {/* <br /> */}
                        {/* <a
                                    href="/profile"
                                    className="logo fs-6 text-secondary w-100 text-capitalize"
                              >
                                    {userCtx?.user
                                          ? userCtx?.user.company_name
                                          : ""}
                              </a> */}
                        {/* </div> */}
                        <OverlayScrollbarsComponent
                              element="div"
                              className="nav"
                              options={{ scrollbars: { autoHide: "scroll" } }}
                              defer
                        >
                              <ul>
                                    <div className="up">
                                          <MenuItem
                                                title="Dashboard"
                                                icon="bx bxs-widget"
                                                link="/dashboard"
                                          />
                                          <MenuItem
                                                title="Contact Book"
                                                icon="bx bx-book-bookmark"
                                                link="/contact/books"
                                          />
                                          <MenuItem
                                                title="Brands"
                                                icon="bx bx-briefcase-alt-2"
                                                link="/brands"
                                          />
                                          <MenuItem
                                                title="Products"
                                                icon="bx bx-cube"
                                                link="/products"
                                          />
                                          <MenuItem
                                                title="Orders"
                                                icon="bx bx-car"
                                                link="/orders"
                                          />
                                          {/* <MenuItem
                                                title="Coupons"
                                                svg={<Coupon />}
                                                link="/coupons"
                                          /> */}
                                          <MenuItem
                                                title="Account & Setting"
                                                icon="bx bx-user"
                                                link="/profile"
                                          />
                                          <MenuItem
                                                title="Support Ticket"
                                                icon="bx bx-purchase-tag-alt"
                                                link="/support-tickets"
                                          />
                                    </div>
                              </ul>
                        </OverlayScrollbarsComponent>

                        <BottomNavigation onLogoutHandler={onLogoutHandler} />
                  </div>
            </>
      );
};

export default Sidebar;
