import React, { useState } from "react";
import {
      ProductListContent,
      ProductListItems,
      ProductListTitle,
      QuoteCountBox,
} from "./style";
import { Offcanvas } from "react-bootstrap";
import CategoryFilter from "../CategoryFilter";
import BrandFilter from "../BrandFilter";
import { FilterIcon, ProductAdd } from "../../../../icons";

const FilterProduct = ({
      loading,
      categories,
      brands,
      quotations,
      handleBrandChange,
      handleCategoryChange,
      totalProducts,
}) => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                        <QuoteCountBox onClick={handleShow} role="button">
                              <div>Filter</div>
                              <div>
                                    <FilterIcon />
                              </div>
                        </QuoteCountBox>
                        <div>{totalProducts} products found</div>
                        <QuoteCountBox href="/products/manage/checkout">
                              <div>{quotations.length}</div>
                              <div>
                                    <ProductAdd />
                              </div>
                        </QuoteCountBox>
                  </div>
                  <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Filter By</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                              <ProductListItems>
                                    <ProductListTitle>
                                          <h2>Category</h2>
                                    </ProductListTitle>
                                    <ProductListContent>
                                          <CategoryFilter
                                                handleChangeCategory={
                                                      handleCategoryChange
                                                }
                                                loading={loading}
                                                categories={categories}
                                          />
                                    </ProductListContent>
                              </ProductListItems>
                              <ProductListItems>
                                    <ProductListTitle>
                                          <h2>Brand</h2>
                                    </ProductListTitle>
                                    <ProductListContent>
                                          <BrandFilter
                                                loading={loading}
                                                brands={brands}
                                                changeChecked={
                                                      handleBrandChange
                                                }
                                                type="brand"
                                          />
                                    </ProductListContent>
                              </ProductListItems>
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default FilterProduct;
