import React from "react";
import { Link } from "react-router-dom";
import { ViewIcon } from "../../../icons";

const ViewButton = ({ link, classes }) => {
      return (
            <>
                  <Link to={link} className={classes}>
                        <ViewIcon color="green" />
                  </Link>
            </>
      );
};

export default ViewButton;
