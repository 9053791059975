import React from "react";
import { Link } from "react-router-dom";

const DashboardCard = ({ title, icon, url, type, total, loading }) => {
      return (
            <>
                  <div className="col-xl-3 col-md-6 col-sm-6">
                        <Link
                              to={url ? url : "#"}
                              className="card card-animate"
                        >
                              <div className="card-body">
                                    <div className="d-flex align-items-center">
                                          <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                      {title}
                                                </p>
                                          </div>
                                          <div className="flex-shrink-0">
                                                <h5 className="text-success fs-14 mb-0">
                                                      <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                                                </h5>
                                          </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-3">
                                          <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                                                      {type}{" "}
                                                      <span
                                                            className="counter-value"
                                                            data-target="0"
                                                      >
                                                            {total}
                                                      </span>
                                                </h4>
                                          </div>
                                          <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-soft-success rounded fs-3">
                                                      <i className={icon}></i>
                                                </span>
                                          </div>
                                    </div>
                              </div>
                        </Link>
                  </div>
            </>
      );
};

export default DashboardCard;
