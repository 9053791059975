import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Header = ({
      title,
      search,
      link,
      linkTitle,
      link2,
      link2Title,
}) => {
      return (
            <>
                  <Col lg={12}>
                        <div className="product__content bg-white">
                              <div className="product__content-item">
                                    <div className="product__content-item__header">
                                          <h1 className="product__content-item__title mb-0">
                                                {title}
                                          </h1>
                                    </div>
                                    <div
                                          className={
                                                search
                                                      ? "w-100 d-flex align-items-center"
                                                      : "w-100 ms-auto float-end"
                                          }
                                    >
                                          {search && (
                                                <form
                                                      role="search"
                                                      className="w-100 d-flex align-items-center position-relative"
                                                >
                                                      <label className="product__content-item__label">
                                                            Search
                                                      </label>
                                                      <button className="product__content-item__btn">
                                                            <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  fill="none"
                                                                  viewBox="0 0 24 24"
                                                                  stroke="currentColor"
                                                                  className="w-5 h-5"
                                                            >
                                                                  <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                  ></path>
                                                            </svg>
                                                      </button>
                                                      <input
                                                            type="text"
                                                            id="search"
                                                            name="searchText"
                                                            className="product__content-item__input"
                                                            placeholder="Type your query and press enter"
                                                            aria-label="Search"
                                                            autoComplete="off"
                                                      />
                                                </form>
                                          )}
                                          {link2 && (
                                                <Link
                                                      style={{
                                                            dispaly: "inherit",
                                                      }}
                                                      className="product__content-add bg-accent text-white ms-3 float-end"
                                                      to={link2}
                                                >
                                                      <span>{link2Title}</span>
                                                </Link>
                                          )}
                                          {link && (
                                                <Link
                                                      style={{
                                                            dispaly: "inherit",
                                                      }}
                                                      className="product__content-add bg-accent text-white ms-3 float-end"
                                                      to={link}
                                                >
                                                      <span>{linkTitle}</span>
                                                </Link>
                                          )}
                                    </div>
                              </div>
                        </div>
                  </Col>
            </>
      );
};
