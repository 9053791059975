import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { CardBody } from 'reactstrap';

const ProfileNavigation = () => {
      return (
            <>
                  <div className='profile__sidebar-container'>
                        <Card className='profile__sidebar border-0'>
                              <CardBody className='text-center'>
                                    <ListGroup className='text-start'>
                                          <NavLink to="/profile" className="list-group-item">
                                                Seller Account
                                          </NavLink>
                                          <NavLink to="/seller-logo" className="list-group-item">
                                                Seller Logo
                                          </NavLink>
                                          <NavLink to="/business-information" className="list-group-item">
                                                Business Information
                                          </NavLink>
                                          <NavLink to="/person-in-charge" className="list-group-item">
                                                Person In Charge
                                          </NavLink>
                                          <NavLink to="/bank-account" className="list-group-item">
                                                Bank Account
                                          </NavLink>

                                          <NavLink to="/warehouse-address" className="list-group-item">
                                                Warehouse Address
                                          </NavLink>
                                          <NavLink to="/return-address" className="list-group-item">
                                                Return Address
                                          </NavLink>
                                          <NavLink to="/change-password" className="list-group-item">
                                                Change Password
                                          </NavLink>
                                    </ListGroup>
                              </CardBody>
                        </Card>
                  </div>
            </>
      )
}

export default ProfileNavigation;