import styled from "styled-components";

export const Button = styled.button`
      display: inline-flex;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      line-height: 1;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      height: 3rem;
      width: ${(props) => props.width || "200px"};
      border-radius: 0.25rem;
      border-width: 1px;
      border-color: transparent;
      outline: 0;
      color: white;
      background-color: #e86b19;

      .spinner-border {
            border-width: 1.5px;
      }
`;
