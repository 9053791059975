import React from "react";
import { Link } from "react-router-dom";

const BottomNavigation = ({ onLogoutHandler }) => {
      return (
            <>
                  <div className="sidebar-custom d-flex align-items-end">
                        <div>
                              <Link
                                    to="/help"
                                    title="Help"
                                    className="text-secondary text-aligns-center text-center"
                              >
                                    <i className="bx bx-help-circle fs-3"></i>
                              </Link>
                        </div>
                        <div>
                              <Link
                                    title="Logout"
                                    to="#"
                                    onClick={onLogoutHandler}
                                    className="text-secondary"
                              >
                                    <i className="bx bx-log-in-circle icon fs-3"></i>
                              </Link>
                        </div>
                  </div>
            </>
      );
};

export default BottomNavigation;
