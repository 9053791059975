import { styled } from "styled-components";

export const ProductContainer = styled.div`
      display: flex;
      align-items: center;

      img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            background-color: #f2f2f2;
            padding: 5px;
      }
`;

export const CategoryTitle = styled.div.attrs(() => ({
      className: "text-muted",
}))`
      font-size: 14px;
`;
