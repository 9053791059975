import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/dashboard/Sidebar";
import { Header } from "../../layouts/Header";

const CouponEdit = () => {
      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <Header
                                                      title="Brand Promotion"
                                                      link="/promotions/create"
                                                      linkTitle="Add New"
                                                />
                                                <div className="col-md-12"></div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default CouponEdit;
