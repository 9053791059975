import React, { useState } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import FormInput from "../../components/form/FormInput";
import FormTitle from "../../components/form/FormTitle";

const ProfileUpdate = () => {
      const [sellerName, setSellerName] = useState("");
      const [lastName, setLastName] = useState("");
      const [company, setCompany] = useState("");
      const [email, setEmail] = useState("");
      const [bankName, setBankName] = useState("");
      const [bankAccount, setBankAccount] = useState("");
      const [address, setAddress] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [landline, setLandline] = useState("");
      const [panFile, setPanFile] = useState("");
      const [logo, setLogo] = useState("");
      const [logoUrl, setLogoUrl] = useState("");
      const [remarks, setRemarks] = useState("");
      const [contactPerson, setContactPerson] = useState("");
      const [contactPersonNumber, setContactPersonNumber] = useState("");
      const [authorizeBrand, setAuthorizeBrand] = useState("");

      const handleImage = (file) => {
            setLogo(file);
            setLogoUrl(URL.createObjectURL(file));
      };

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="form__header">
                                                            <h1 className="form__header-title">
                                                                  Update Profile
                                                            </h1>
                                                      </div>
                                                      <form onSubmit="form__group">
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Your Logo"
                                                                        summary="Upload your logo image here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <section className="position-relative">
                                                                              <div
                                                                                    role="button"
                                                                                    tabIndex="0"
                                                                                    className="form__group-upload"
                                                                              >
                                                                                    <input
                                                                                          accept="image/*"
                                                                                          onChange={(
                                                                                                event
                                                                                          ) =>
                                                                                                handleImage(
                                                                                                      event
                                                                                                            .target
                                                                                                            .files[0]
                                                                                                )
                                                                                          }
                                                                                          type="file"
                                                                                          autoComplete="off"
                                                                                          tabIndex="-1"
                                                                                          className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                                                    />
                                                                                    <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          width="41px"
                                                                                          height="30px"
                                                                                          viewBox="0 0 40.909 30"
                                                                                          className="text-muted-light"
                                                                                    >
                                                                                          <g transform="translate(0 -73.091)">
                                                                                                <path
                                                                                                      data-name="Path 2125"
                                                                                                      d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                                                      transform="translate(0)"
                                                                                                      fill="currentColor"
                                                                                                ></path>
                                                                                          </g>
                                                                                    </svg>
                                                                                    <p className="form__group-description">
                                                                                          <span className="text-accent font-semibold">
                                                                                                Upload
                                                                                                an
                                                                                                image
                                                                                          </span>
                                                                                          or
                                                                                          drag
                                                                                          and
                                                                                          drop{" "}
                                                                                          <br />
                                                                                          <span className="form__group-drag">
                                                                                                PNG,
                                                                                                JPG
                                                                                          </span>
                                                                                    </p>
                                                                              </div>
                                                                        </section>
                                                                        {logoUrl && (
                                                                              <img
                                                                                    src={
                                                                                          logoUrl
                                                                                    }
                                                                                    className="rounded mt-3 border p-1"
                                                                                    style={{
                                                                                          height: "60px",
                                                                                          width: "60px",
                                                                                    }}
                                                                              />
                                                                        )}
                                                                  </div>
                                                            </div>
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Company Information"
                                                                        summary="Add your company details and necessary information from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormInput
                                                                              label="Seller Name"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleInput={(
                                                                                    event
                                                                              ) =>
                                                                                    setSellerName(
                                                                                          event
                                                                                                .target
                                                                                                .value
                                                                                    )
                                                                              }
                                                                        />
                                                                  </div>
                                                            </div>
                                                      </form>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProfileUpdate;
