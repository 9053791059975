import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/dashboard/Sidebar";

import axios from "axios";
import { toast } from "react-hot-toast";
import FormTitle from "../../components/form/FormTitle";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
import Progress from "react-progress-2";
import UserContext from "../../stores/UserContext";
import { Col, Form, Row } from "react-bootstrap";
import { FormButton } from "../../components/form";
import { useParams } from "react-router-dom";

const options = [
      {
            id: "price",
            title: "Price",
      },
      {
            id: "on_demand",
            title: "On Demand",
      },
      {
            id: "on_request",
            title: "On Request",
      },
];

const ProductRequestEdit = () => {
      const { productId } = useParams();
      const [imageUrl, setImageUrl] = useState("");
      const userCtx = useContext(UserContext);

      const [brands, setBrands] = useState([]);
      const [units, setUnits] = useState([]);
      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);

      const [item, setItem] = useState("");
      const [childCategory, setChildCategory] = useState("");

      const [image, setImage] = useState("");
      const [brand, setBrand] = useState("");
      const [category, setCategory] = useState("");
      const [subCategory, setSubCategory] = useState("");
      const [productName, setProductName] = useState("");
      const [price, setPrice] = useState("");
      const [unit, setUnit] = useState("");
      const [quantity, setQuantity] = useState("");
      const [priceType, setPriceType] = useState("");
      const [dealerPrice, setDealerPrice] = useState("");
      const [sellerPrice, setSellerPrice] = useState("");
      const [description, setDescription] = useState("");
      const [selectedCategory, setSelectedCategory] = useState("");

      const handleImage = (file) => {
            setImage(file);
            setImageUrl(URL.createObjectURL(file));
      };

      async function handleCategories() {
            Progress.show();

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/product_requests/${productId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setImageUrl(response.data.product.image);
                              setBrand(response.data.product.brand_id);
                              setProductName(response.data.product.title);
                              setPriceType(response.data.product.price_type);
                              setDescription(response.data.product.description);
                              setQuantity(response.data.product.quantity);
                              setSellerPrice(
                                    response.data.product.retailer_price
                              );
                              setSelectedCategory(
                                    response.data.product.category_id
                              );
                              setDealerPrice(
                                    response.data.product.dealer_price
                              );
                              setUnit(response.data.product.unit);
                              setPrice(response.data.product.mrp_price);
                              setItem(response.data.category);
                              setChildCategories(response.data.childCategories);
                              setChildCategory(response.data.childCategory);
                              setSubCategories(response.data.subCategories);
                              setSubCategory(response.data.subCategory);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/product_requests/brand/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands(response.data.brands);
                              setUnits(response.data.units);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategories(response.data.categories);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            Progress.hide();
      }

      useEffect(() => {
            handleCategories();
      }, []);

      async function handleSubCatgories(value) {
            Progress.show();
            setSelectedCategory(value);
            setSubCategories([]);
            setChildCategories([]);
            const data = {
                  id: value,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setSubCategories(response.data.categories);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            Progress.hide();
      }

      async function handleChildCatgories(value) {
            Progress.show();
            setSubCategory(value);
            setSelectedCategory(value);
            setChildCategories([]);

            const data = {
                  id: value,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setChildCategories(response.data.categories);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            Progress.hide();
      }

      async function handleFormSubmit(event) {
            event.preventDefault();
            const data = {
                  product_name: productName,
                  brand: brand,
                  category: selectedCategory,
                  unit: unit,
                  description: description,
                  price: price,
                  price_type: priceType,
                  seller_price: sellerPrice,
                  dealer_price: dealerPrice,
                  quantity: quantity,
                  image: image,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/product_requests/${productId}`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.product_name) {
                                    toast.error(
                                          response.data.message.product_name
                                    );
                              }
                              if (response.data.message.brand) {
                                    toast.error(response.data.message.brand);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.unit) {
                                    toast.error(response.data.message.unit);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.price) {
                                    toast.error(response.data.message.price);
                              }
                              if (response.data.message.price_type) {
                                    toast.error(
                                          response.data.message.price_type
                                    );
                              }
                              if (response.data.message.dealer_price) {
                                    toast.error(
                                          response.data.message.dealer_price
                                    );
                              }
                              if (response.data.message.seller_price) {
                                    toast.error(
                                          response.data.message.seller_price
                                    );
                              }
                              if (response.data.message.quantity) {
                                    toast.error(response.data.message.quantity);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.errorMessage) {
                                    toast.error(response.data.errorMessage);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <Row>
                                                <Col lg={12}>
                                                      <div className="form__header">
                                                            <h1 className="form__header-title">
                                                                  Edit Product
                                                            </h1>
                                                      </div>
                                                      <Form
                                                            onSubmit={
                                                                  handleFormSubmit
                                                            }
                                                      >
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Featured Image"
                                                                        summary="Upload your product featured image here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <section className="position-relative">
                                                                              <div
                                                                                    role="button"
                                                                                    tabIndex="0"
                                                                                    className="form__group-upload"
                                                                              >
                                                                                    <input
                                                                                          accept="image/*"
                                                                                          onChange={(
                                                                                                event
                                                                                          ) =>
                                                                                                handleImage(
                                                                                                      event
                                                                                                            .target
                                                                                                            .files[0]
                                                                                                )
                                                                                          }
                                                                                          type="file"
                                                                                          autoComplete="off"
                                                                                          tabIndex="-1"
                                                                                          className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                                                    />
                                                                                    <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          width="41px"
                                                                                          height="30px"
                                                                                          viewBox="0 0 40.909 30"
                                                                                          className="text-muted-light"
                                                                                    >
                                                                                          <g transform="translate(0 -73.091)">
                                                                                                <path
                                                                                                      data-name="Path 2125"
                                                                                                      d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                                                      transform="translate(0)"
                                                                                                      fill="currentColor"
                                                                                                ></path>
                                                                                          </g>
                                                                                    </svg>
                                                                                    <p className="form__group-description">
                                                                                          <span className="text-accent font-semibold">
                                                                                                Upload
                                                                                                an
                                                                                                image
                                                                                          </span>
                                                                                          or
                                                                                          drag
                                                                                          and
                                                                                          drop{" "}
                                                                                          <br />
                                                                                          <span className="form__group-drag">
                                                                                                PNG,
                                                                                                JPG
                                                                                          </span>
                                                                                    </p>
                                                                              </div>
                                                                        </section>
                                                                        {imageUrl && (
                                                                              <img
                                                                                    src={
                                                                                          imageUrl
                                                                                    }
                                                                                    className="rounded mt-3 border p-1"
                                                                                    style={{
                                                                                          height: "60px",
                                                                                          width: "60px",
                                                                                    }}
                                                                              />
                                                                        )}
                                                                  </div>
                                                            </div>
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Brand &amp; Categories"
                                                                        summary="Select product brand and categories from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormSelect
                                                                              classes="mb-3"
                                                                              label="Brand"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleSelect={
                                                                                    setBrand
                                                                              }
                                                                              value={
                                                                                    brand
                                                                              }
                                                                              data={
                                                                                    brands
                                                                              }
                                                                        />
                                                                        <FormSelect
                                                                              classes="mb-4"
                                                                              label="Categories"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    item
                                                                              }
                                                                              handleSelect={
                                                                                    handleSubCatgories
                                                                              }
                                                                              data={
                                                                                    categories
                                                                              }
                                                                        />
                                                                        {subCategories.length >
                                                                              0 && (
                                                                              <FormSelect
                                                                                    classes="mb-4"
                                                                                    label="Sub Categories"
                                                                                    required={
                                                                                          true
                                                                                    }
                                                                                    value={
                                                                                          subCategory
                                                                                    }
                                                                                    handleSelect={
                                                                                          handleChildCatgories
                                                                                    }
                                                                                    data={
                                                                                          subCategories
                                                                                    }
                                                                              />
                                                                        )}
                                                                        {childCategories.length >
                                                                              0 && (
                                                                              <FormSelect
                                                                                    classes="mb-4"
                                                                                    label="Child Categories"
                                                                                    required={
                                                                                          true
                                                                                    }
                                                                                    handleSelect={
                                                                                          setSelectedCategory
                                                                                    }
                                                                                    value={
                                                                                          childCategory
                                                                                    }
                                                                                    data={
                                                                                          childCategories
                                                                                    }
                                                                              />
                                                                        )}
                                                                  </div>
                                                            </div>
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Description"
                                                                        summary="Add your product description and necessary information from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormInput
                                                                              label="Name"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setProductName
                                                                              }
                                                                              value={
                                                                                    productName
                                                                              }
                                                                        />
                                                                        <FormSelect
                                                                              label="Unit"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleSelect={
                                                                                    setUnit
                                                                              }
                                                                              value={
                                                                                    unit
                                                                              }
                                                                              data={
                                                                                    units
                                                                              }
                                                                        />
                                                                        <div className="mb-4">
                                                                              <label className="form__group-label">
                                                                                    Description
                                                                              </label>
                                                                              <textarea
                                                                                    className="form__group-message"
                                                                                    autoComplete="off"
                                                                                    autoCorrect="off"
                                                                                    autoCapitalize="off"
                                                                                    spellCheck="false"
                                                                                    rows="4"
                                                                                    value={
                                                                                          description
                                                                                    }
                                                                                    onChange={(
                                                                                          event
                                                                                    ) =>
                                                                                          setDescription(
                                                                                                event
                                                                                                      .target
                                                                                                      .value
                                                                                          )
                                                                                    }
                                                                              ></textarea>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="form__group-information">
                                                                  <FormTitle
                                                                        title="Simple Product Information"
                                                                        summary="Add your simple product description and necessary information from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormSelect
                                                                              classes="mb-3"
                                                                              label="Price Type"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleSelect={
                                                                                    setPriceType
                                                                              }
                                                                              value={
                                                                                    priceType
                                                                              }
                                                                              data={
                                                                                    options
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Price"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="number"
                                                                              minValue="0"
                                                                              handleInput={
                                                                                    setPrice
                                                                              }
                                                                              value={
                                                                                    price
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Dealer Percentage"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    dealerPrice
                                                                              }
                                                                              type="number"
                                                                              minValue="0"
                                                                              handleInput={
                                                                                    setDealerPrice
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Sale Price"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    sellerPrice
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setSellerPrice
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              label="Quantity"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    quantity
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setQuantity
                                                                              }
                                                                        />
                                                                  </div>
                                                            </div>
                                                            <div className="mb-4 text-end">
                                                                  <FormButton title="Update Product" />
                                                            </div>
                                                      </Form>
                                                </Col>
                                          </Row>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductRequestEdit;
