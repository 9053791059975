import React from "react";
import Pagination from "react-js-pagination";
import { PaginationContainer } from "./styles";

const TableFooter = ({ perPages, totalItems, currentPage, getData }) => {
      return (
            <>
                  <PaginationContainer>
                        <div>
                              Showing 1 To {perPages} of {totalItems} Entries
                        </div>
                        <div>
                              {totalItems > 10 && (
                                    <Pagination
                                          activePage={currentPage}
                                          totalItemsCount={totalItems}
                                          itemsCountPerPage={perPages}
                                          onChange={(pageNumber) =>
                                                getData(pageNumber)
                                          }
                                          itemClass="page-item"
                                          linkClass="page-link"
                                          firstPageText="First"
                                          lastPageText="Last"
                                    />
                              )}
                        </div>
                  </PaginationContainer>
            </>
      );
};

export default TableFooter;
