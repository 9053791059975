import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import "../css/style.css";

import RecentOrder from "./RecentOrder";
import DashboardCard from "../../components/dashboard/DashboardCard";
import axios from "axios";
import MobileMenu from "../../components/dashboard/MobileMenu";
import Progress from "react-progress-2";
import toast from "react-hot-toast";
import UserContext from "../../stores/UserContext";

const Dashboard = () => {
      const userCtx = useContext(UserContext);
      const [totalContact, setTotalContact] = useState(0);
      const [totalProduct, setTotalProduct] = useState(0);
      const [totalOrder, setTotalOrder] = useState(0);
      const [totalBrand, setTotalBrand] = useState(0);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            Progress.show();
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/dashboard`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTotalContact(response.data.contacts);
                              setTotalBrand(response.data.brands);
                              setTotalOrder(response.data.orders);
                              setTotalProduct(response.data.products);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            Progress.hide();
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />

                        <div className="header">
                              <MobileMenu />
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <h2>
                                                            Welcome,{" "}
                                                            {userCtx?.user.name}
                                                      </h2>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                      <div className="main-dashboard ">
                                                            <div
                                                                  className="row g-3"
                                                                  style={{
                                                                        overflow: "hidden",
                                                                  }}
                                                            >
                                                                  <DashboardCard
                                                                        title="Total Brand"
                                                                        total={
                                                                              totalBrand
                                                                        }
                                                                        url="/brands"
                                                                        summary="View all brands"
                                                                        icon="bx bx-briefcase-alt-2"
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />

                                                                  <DashboardCard
                                                                        title="Total Product"
                                                                        total={
                                                                              totalProduct
                                                                        }
                                                                        url="/products"
                                                                        summary="View all products"
                                                                        icon="bx bx-cube-alt"
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />

                                                                  <DashboardCard
                                                                        title="Total Orders"
                                                                        total={
                                                                              totalOrder
                                                                        }
                                                                        url="/orders"
                                                                        summary="View all orders"
                                                                        icon="bx bx-dollar-circle"
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />

                                                                  <DashboardCard
                                                                        title="Contact Book"
                                                                        total={
                                                                              totalContact
                                                                        }
                                                                        url="/contact/books"
                                                                        summary="View all notifications"
                                                                        icon="bx bx-book-bookmark"
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                                <RecentOrder />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default Dashboard;
