import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import {
      Alert,
      Card,
      CardBody,
      CardHeader as PostTitle,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import UserContext from "../../stores/UserContext";
import { AttachmentIcon } from "../../icons";
import { AttachmentImage } from "./style";
import { CardHeader, LinkButton } from "../../components/commons";
import SupportReply from "./SupportReply";

const SupportShow = () => {
      const { ticketId } = useParams();
      const userCtx = useContext(UserContext);
      const [support, setSupport] = useState("");
      const [supports, setSupports] = useState([]);

      const [replyModal, setReplyModal] = useState(false);
      const [loading, setLoading] = useState(false);

      const [loadingModal, setLoadingModal] = useState(false);

      const handleModal = () => {
            setLoadingModal(true);
            setTimeout(() => {
                  setLoadingModal(false);
                  setReplyModal(true);
            }, 3000);
      };

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/support_tickets/${ticketId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((respoonse) => {
                        if (respoonse.data.result === "success") {
                              setSupport(respoonse.data.support);
                              setSupports(respoonse.data.support.supports);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleClose = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/support_tickets/${ticketId}/close`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    setLoading(false);
                                    setSupport({ ...support, ["status"]: 0 });
                                    toast.success(response.data.message);
                              }
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                              toast.error(error.message);
                        }, 3000);
                  });
      };

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          {support.status === 0 ? (
                                                <Alert
                                                      variant="warning"
                                                      dismissible
                                                >
                                                      This ticket is closed. You
                                                      may reply to this ticket
                                                      to reopen it.
                                                </Alert>
                                          ) : null}
                                          <Card>
                                                <CardHeader
                                                      title={`View Ticket #${support.support_no}`}
                                                >
                                                      <LinkButton
                                                            title="Reply"
                                                            handleClick={
                                                                  handleModal
                                                            }
                                                            loading={
                                                                  loadingModal
                                                            }
                                                            width="77px"
                                                      />
                                                      <LinkButton
                                                            handleClick={() =>
                                                                  handleClose()
                                                            }
                                                            loading={loading}
                                                            title="Close"
                                                            width="77px"
                                                      />
                                                      <LinkButton
                                                            link="/support-tickets"
                                                            title="Back"
                                                      />
                                                </CardHeader>
                                                {supports?.length > 0
                                                      ? supports?.map(
                                                              (item, index) => (
                                                                    <>
                                                                          <PostTitle
                                                                                key={
                                                                                      index
                                                                                }
                                                                                className="border-top border-bottom"
                                                                          >
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                      <h6 className="mb-0">
                                                                                            Posted
                                                                                            by{" "}
                                                                                            {
                                                                                                  item.user_name
                                                                                            }{" "}
                                                                                            on{" "}
                                                                                            {
                                                                                                  item.date
                                                                                            }
                                                                                      </h6>
                                                                                      <div>
                                                                                            {
                                                                                                  item.user_type
                                                                                            }
                                                                                      </div>
                                                                                </div>
                                                                          </PostTitle>
                                                                          <CardBody>
                                                                                <div
                                                                                      dangerouslySetInnerHTML={{
                                                                                            __html: item.description,
                                                                                      }}
                                                                                ></div>
                                                                          </CardBody>
                                                                    </>
                                                              )
                                                        )
                                                      : null}
                                                <PostTitle className="border-top border-bottom">
                                                      <div className="d-flex align-items-center justify-content-between">
                                                            <h6 className="mb-0">
                                                                  Post by{" "}
                                                                  {
                                                                        support.user_name
                                                                  }{" "}
                                                                  on{" "}
                                                                  {support.date}
                                                            </h6>
                                                            <div>Owner</div>
                                                      </div>
                                                </PostTitle>
                                                <CardBody>
                                                      <div
                                                            dangerouslySetInnerHTML={{
                                                                  __html: support.message,
                                                            }}
                                                      ></div>
                                                      {support.attachment && (
                                                            <div className="border-top pt-3">
                                                                  <div className="d-flex align-items-center">
                                                                        <AttachmentIcon />
                                                                        Attachment
                                                                  </div>
                                                                  <div className="d-flex">
                                                                        <AttachmentImage
                                                                              to={
                                                                                    support.attachment
                                                                              }
                                                                              download={
                                                                                    true
                                                                              }
                                                                              className="border rounded"
                                                                        >
                                                                              <img
                                                                                    src={
                                                                                          support.attachment
                                                                                    }
                                                                                    alt=""
                                                                              />
                                                                        </AttachmentImage>
                                                                  </div>
                                                            </div>
                                                      )}
                                                </CardBody>
                                          </Card>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <SupportReply
                        support={support}
                        setSupport={setSupport}
                        show={replyModal}
                        slug={ticketId}
                        supports={supports}
                        setSupports={setSupports}
                        handleClose={() => setReplyModal(false)}
                  />
            </>
      );
};

export default SupportShow;
