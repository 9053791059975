import React from "react";
import { Button } from "./style";
import { Spinner } from "react-bootstrap";

const FormButton = ({ loading, type, handleClick, title, classes, width }) => {
      return (
            <>
                  <Button
                        type={type || "submit"}
                        onClick={handleClick}
                        className={classes}
                        width={width}
                  >
                        {loading ? <Spinner /> : title || "Submit"}
                  </Button>
            </>
      );
};

export default FormButton;
