import React, { useContext, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import UserContext from "../../stores/UserContext";
import FormFile from "../../components/form/FormFile";
import FormTextarea from "../../components/form/FormTextarea";
import { FormButton } from "../../components/form";

import $ from "jquery";
import axios from "axios";
import toast from "react-hot-toast";

const SupportReply = ({
      slug,
      show,
      handleClose,
      setSupports,
      supports,
      support,
      setSupport,
}) => {
      const userCtx = useContext(UserContext);
      const [loading, setLoading] = useState(false);
      const [clearData, setClearData] = useState(false);
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [notes, setNotes] = useState("");

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            $("button[type=submit]").prop("disabled", true);

            const data = {
                  image: image,
                  description: notes,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/support_tickets/${slug}`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                                    setSupports([
                                          response.data.item,
                                          ...supports,
                                    ]);
                                    setSupport({ ...support, ["status"]: 1 });
                                    setClearData(false);
                                    handleClose();
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message.attachment) {
                                          toast.error(
                                                response.data.message.attachment
                                          );
                                    }
                                    if (response.data.message.description) {
                                          toast.error(
                                                response.data.message
                                                      .description
                                          );
                                    }
                              }
                              $("button[type=submit]").prop("disabled", false);
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              $("button[type=submit]").prop("disabled", false);
                              setLoading(false);
                        }, 3000);
                  });
      };

      return (
            <>
                  <Modal
                        size="lg"
                        show={show}
                        backdrop="static"
                        onHide={handleClose}
                  >
                        <Modal.Header closeButton className="py-2">
                              <Modal.Title className="m-0">Reply</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <Form onSubmit={handleSubmit}>
                                    <FormTextarea
                                          setNotes={setNotes}
                                          clearData={clearData}
                                    />
                                    <Col lg={4}>
                                          <div className="position-relative">
                                                <FormFile
                                                      handleImage={setImage}
                                                      title="Attachments"
                                                      handleImageUrl={
                                                            setImageUrl
                                                      }
                                                      imageUrl={imageUrl}
                                                />
                                          </div>
                                    </Col>
                                    <div className="mt-4">
                                          <FormButton loading={loading} />
                                    </div>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default SupportReply;
