import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { BrandItem } from "./style";

const BrandList = ({ setPageId }) => {
      const [loading, setLoading] = useState(false);
      const [brands, setBrands] = useState([]);

      const loadData = async () => {
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/brands`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY,
                        },
                  })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setLoading(false);
                              setBrands(response.data.brands);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            setLoading(true);
            loadData();
      }, []);

      return (
            <>
                  <div className="page-brand">
                        <div className="category__body mx-auto">
                              <div className="category__content">
                                    <div className="category__content-body">
                                          <div className="category__content-header clearfix d-flex align-items-center">
                                                <h2 className="fs-3">
                                                      Assosiated Brands
                                                </h2>
                                          </div>
                                          <div className="d-flex w-100 brand__container">
                                                <Swiper
                                                      slidesPerView={4}
                                                      spaceBetween={10}
                                                      autoplay={{ delay: 1500 }}
                                                      breakpoints={{
                                                            640: {
                                                                  slidesPerView: 4,
                                                            },
                                                            768: {
                                                                  slidesPerView: 5,
                                                            },
                                                            1024: {
                                                                  slidesPerView: 6,
                                                            },
                                                            1280: {
                                                                  slidesPerView: 8,
                                                            },
                                                      }}
                                                      modules={[Autoplay]}
                                                >
                                                      {!loading ? (
                                                            brands.map(
                                                                  (
                                                                        brand,
                                                                        index
                                                                  ) => (
                                                                        <SwiperSlide
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <div
                                                                                    className="swiper-slide h-100 position-relative overflow-hidden swiper-slide-duplicate"
                                                                                    data-swiper-slide-index="2"
                                                                              >
                                                                                    <BrandItem>
                                                                                          <img
                                                                                                alt={
                                                                                                      brand.title
                                                                                                }
                                                                                                src={
                                                                                                      brand.image
                                                                                                }
                                                                                                decoding="async"
                                                                                                data-nimg="responsive"
                                                                                                className="product__image object-cover"
                                                                                          />
                                                                                    </BrandItem>
                                                                              </div>
                                                                        </SwiperSlide>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <div className="">
                                                                        Loading
                                                                  </div>
                                                            </>
                                                      )}
                                                </Swiper>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div className="page-footer">
                        <span>©2024 Infinity Infosys Pvt. Ltd.</span>
                        <ul className="float-end">
                              <li>
                                    {" "}
                                    <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                                setPageId("terms-of-us")
                                          }
                                    >
                                          Terms and Condition
                                    </div>{" "}
                              </li>
                              <li>
                                    {" "}
                                    <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                                setPageId("privacy-policy")
                                          }
                                    >
                                          Privacy Policy
                                    </div>{" "}
                              </li>
                        </ul>
                  </div>
            </>
      );
};

export default BrandList;
