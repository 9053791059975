import React, { useState } from "react";

import $ from "jquery";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";

const LoginCard = () => {
      const [email, setEmail] = useState("");
      const [isLoading, setIsLoading] = useState(false);
      const [password, setPassword] = useState("");
      const [type, setType] = useState("text");

      const [redirect, setRedirect] = useState(false);

      async function handleSubmit(event) {
            setIsLoading(true);
            event.preventDefault();
            $("button[type=submit]").prop("disabled", true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/login`,
                        {
                              email: email,
                              password: password,
                        },
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);

                                    localStorage.setItem("isLoggedIn", true);
                                    localStorage.setItem(
                                          "token",
                                          JSON.stringify(
                                                response.data.token.token
                                          )
                                    );
                                    localStorage.setItem(
                                          "tokenExpiration",
                                          new Date(
                                                new Date().getTime() +
                                                      response.data.token
                                                            .token_validity *
                                                            1000
                                          )
                                    );
                                    window.location.reload();
                              }
                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (response.data.message.password) {
                                                toast.error(
                                                      response.data.message
                                                            .password
                                                );
                                          }
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                    }
                              }
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                              toast.error(error.message);
                        }, 3000);
                  });
      }

      if (redirect) {
            return <Navigate to="/dashboard" />;
      }
      const login = localStorage.getItem("isLoggedIn");
      if (login) {
            return <Navigate to="/dashboard" />;
      }

      return (
            <>
                  <div className="page-ath-form">
                        <h2 className="page-ath-heading">
                              Sign in <small>with your seller Account</small>
                        </h2>
                        <Form onSubmit={handleSubmit}>
                              <Row>
                                    <Col lg={12} className="input-item mb-2">
                                          <FormLabel>
                                                Email Address{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type="email"
                                                required={true}
                                                autoComplete="email"
                                                onChange={(event) =>
                                                      setEmail(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={12} className="input-item mb-2">
                                          <FormLabel>
                                                Password{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </FormLabel>
                                          <FormControl
                                                type={type}
                                                required={true}
                                                onClick={() =>
                                                      setType("password")
                                                }
                                                onInput={() =>
                                                      setType("password")
                                                }
                                                onBlur={() =>
                                                      setType("password")
                                                }
                                                onChange={(event) =>
                                                      setPassword(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Col>
                                    {/* <Col lg={12} className="d-block mb-2">
                                          <Link to="/forget-password">
                                                Reset Your Password
                                          </Link>
                                    </Col> */}
                                    <Col lg={12}>
                                          <button
                                                className="btn btn-primary btn-block w-100"
                                                style={{
                                                      backgroundColor:
                                                            "#e86b19",
                                                      borderColor: "#e86b19",
                                                }}
                                          >
                                                Login
                                                {isLoading ? (
                                                      <span
                                                            className="spinner-border spinner-border-sm ml-5"
                                                            role="status"
                                                            aria-hidden="true"
                                                      ></span>
                                                ) : (
                                                      <span></span>
                                                )}
                                          </button>
                                    </Col>
                              </Row>
                              <div className="text-center mt-3">
                                    Request for{" "}
                                    <Link
                                          to="/register"
                                          className="text-center mt-3 mx-auto"
                                    >
                                          <span style={{ color: "#e76b19" }}>
                                                Seller Account
                                          </span>
                                          <div className="gaps-2x"></div>
                                    </Link>
                              </div>
                        </Form>
                  </div>
            </>
      );
};

export default LoginCard;
