import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Sidebar from "../../components/dashboard/Sidebar";
import UserContext from "../../stores/UserContext";
import { Card, Col, Row, Table } from "react-bootstrap";
import {
      CardBody,
      CardHeader,
      DeleteButton,
      LinkButton,
} from "../../components/commons";

const ContactBook = () => {
      const [contacts, setContacts] = useState([]);
      const userCtx = useContext(UserContext);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/contacts`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setContacts(response.data.contacts);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      async function handleContactDelete(item) {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/contacts/${item.slug}/delete`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setLoading(true);
                              loadData();
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <Row>
                                                <Col lg={12}>
                                                      <Card>
                                                            <CardHeader title="Contact Book">
                                                                  <LinkButton
                                                                        title="Add New"
                                                                        link="/contact/books/create"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Table
                                                                        bordered
                                                                        hover
                                                                        className="table-responsive align-items-center"
                                                                  >
                                                                        <thead>
                                                                              <tr>
                                                                                    <th>
                                                                                          S.N
                                                                                    </th>
                                                                                    <th>
                                                                                          Full
                                                                                          Name
                                                                                    </th>
                                                                                    <th>
                                                                                          Job
                                                                                          Title
                                                                                          &
                                                                                          Company
                                                                                    </th>
                                                                                    <th>
                                                                                          Contact
                                                                                          details
                                                                                    </th>
                                                                                    <th>
                                                                                          Address
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "100px",
                                                                                          }}
                                                                                    >
                                                                                          Action
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {contacts.length >
                                                                              0 ? (
                                                                                    contacts.map(
                                                                                          (
                                                                                                contact,
                                                                                                index
                                                                                          ) => (
                                                                                                <tr
                                                                                                      key={
                                                                                                            index
                                                                                                      }
                                                                                                >
                                                                                                      <td>
                                                                                                            {
                                                                                                                  contact.index
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div className="d-flex">
                                                                                                                  <span>
                                                                                                                        <div className="d-flex align-items-center">
                                                                                                                              <div className="flex-grow-2">
                                                                                                                                    <h5 className="fs-6 mb-1">
                                                                                                                                          {
                                                                                                                                                contact.name
                                                                                                                                          }
                                                                                                                                    </h5>
                                                                                                                                    <p className="text-muted mb-0 fs-6">
                                                                                                                                          <span className="fw-medium pe-3">
                                                                                                                                                {
                                                                                                                                                      contact.date
                                                                                                                                                }
                                                                                                                                          </span>
                                                                                                                                    </p>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </span>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div>
                                                                                                                  <div>
                                                                                                                        {
                                                                                                                              contact.job_title
                                                                                                                        }
                                                                                                                  </div>
                                                                                                                  <div>
                                                                                                                        {
                                                                                                                              contact.company_name
                                                                                                                        }
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div>
                                                                                                                  <div>
                                                                                                                        {
                                                                                                                              contact.email
                                                                                                                        }
                                                                                                                  </div>
                                                                                                                  <div>
                                                                                                                        {
                                                                                                                              contact.phone_number
                                                                                                                        }
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  contact.address
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td
                                                                                                            style={{
                                                                                                                  width: "100px",
                                                                                                            }}
                                                                                                      >
                                                                                                            <Link
                                                                                                                  to={`/contact/books/${contact.slug}/edit`}
                                                                                                            >
                                                                                                                  <svg
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        viewBox="0 0 20.547 20.299"
                                                                                                                        fill="currentColor"
                                                                                                                        width="14"
                                                                                                                        className="table__icon"
                                                                                                                  >
                                                                                                                        <g
                                                                                                                              stroke="currentColor"
                                                                                                                              strokeWidth=".4"
                                                                                                                        >
                                                                                                                              <path
                                                                                                                                    data-name="Path 78"
                                                                                                                                    d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                                                                                                              ></path>
                                                                                                                              <path
                                                                                                                                    data-name="Path 79"
                                                                                                                                    d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                                                                                                              ></path>
                                                                                                                        </g>
                                                                                                                  </svg>
                                                                                                            </Link>
                                                                                                            <DeleteButton
                                                                                                                  item={
                                                                                                                        contact
                                                                                                                  }
                                                                                                                  handleDelete={
                                                                                                                        handleContactDelete
                                                                                                                  }
                                                                                                                  loading={
                                                                                                                        loading
                                                                                                                  }
                                                                                                                  setLoading={
                                                                                                                        setLoading
                                                                                                                  }
                                                                                                                  classes="ms-2"
                                                                                                            />
                                                                                                      </td>
                                                                                                </tr>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <tr className="text-center">
                                                                                          <td
                                                                                                colSpan={
                                                                                                      9
                                                                                                }
                                                                                          >
                                                                                                No
                                                                                                contact
                                                                                                found
                                                                                          </td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </Table>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                          </Row>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ContactBook;
