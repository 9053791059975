import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const UserContext = createContext({
      user: [],
      token: null,
});

export const UserContextProvider = (props) => {
      const [user, setUser] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            if (token != null) {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/customer/profile`,
                              {
                                    headers: {
                                          apikey: process.env.REACT_APP_API_KEY,
                                          Authorization: `Bearer ${token}`,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.result === "success") {
                                    setUser(response.data.user);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
      };

      useEffect(() => {
            loadData();
      }, []);

      const context = {
            user: user,
            token: token,
      };

      return (
            <>
                  <UserContext.Provider value={context}>
                        {props.children}
                  </UserContext.Provider>
            </>
      );
};

export default UserContext;
