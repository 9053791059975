import { Link } from "react-router-dom";
import styled from "styled-components";

export const AttachmentImage = styled(Link)`
      width: 160px;
      padding: 10px;
      margin-top: 1rem;
      display: block;

      img {
            width: 100%;
            height: auto;
      }
`;
