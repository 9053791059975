import React, { useContext, useEffect, useState } from "react";
import DashboardCard from "../../../components/dashboard/DashboardCard";
import UserContext from "../../../stores/UserContext";
import axios from "axios";
import toast from "react-hot-toast";

const BrandManage = () => {
      const userCtx = useContext(UserContext);
      const [totalBrands, setTotalBrands] = useState(0);
      const [pendingBrands, setPendingBrands] = useState(0);
      const [cancelledBrands, setCancelledBrands] = useState(0);
      const [rejectedBrands, setRejectedBrands] = useState(0);

      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brands/request/total`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTotalBrands(response.data.totalBrand);
                              setCancelledBrands(response.data.cancelledBrand);
                              setPendingBrands(response.data.pendingBrand);
                              setRejectedBrands(response.data.rejectedBrand);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="row g-3">
                        <DashboardCard
                              title="Requested Brand"
                              total={totalBrands}
                              icon="bx bx-briefcase-alt-2"
                              loading={loading}
                              url="/brands/requests"
                        />
                        <DashboardCard
                              title="Pending Brand"
                              total={pendingBrands}
                              url="/brands/requests"
                              summary="View all"
                              icon="bx bx-time-five"
                              loading={loading}
                        />
                        <DashboardCard
                              title="Rejected Brand"
                              total={rejectedBrands}
                              url="/brands/requests"
                              summary="View all"
                              icon="bx bx-block"
                              loading={loading}
                        />
                        <DashboardCard
                              title="Cancelled Brand"
                              total={cancelledBrands}
                              url="/brands/requests"
                              summary="View all"
                              icon="bx bx-x"
                              loading={loading}
                        />
                  </div>
            </>
      );
};

export default BrandManage;
