import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DeleteIcon } from "../../../icons";

const DeleteButton = ({
      item,
      handleDelete,
      loading,
      setLoading,
      description,
      icon,
      title,
      form,
      classes,
}) => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const handleForm = async () => {
            setLoading(true);

            let status = await handleDelete(item);

            setLoading(false);

            if (status) {
                  handleClose();
            }
      };

      return (
            <>
                  <Link onClick={handleShow} className={classes}>
                        {icon || <DeleteIcon color="#E26770" />}
                  </Link>
                  <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                  >
                        <Modal.Body className="text-center py-5">
                              <h4 className="modal-heading">
                                    {title || "Are You Sure ?"}
                              </h4>
                              <p className="mb-0">
                                    {description ||
                                          "Do you really want to delete these records? This process cannot be undone."}
                              </p>
                        </Modal.Body>
                        <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                    {form ? "Close" : "No"}
                              </Button>
                              {!form && (
                                    <Button
                                          variant="danger"
                                          onClick={handleForm}
                                    >
                                          Yes
                                          {loading && (
                                                <Spinner
                                                      style={{
                                                            height: "18px",
                                                            width: "18px",
                                                            marginLeft: "6px",
                                                      }}
                                                      animation="border"
                                                      role="status"
                                                >
                                                      <span className="visually-hidden">
                                                            Loading...
                                                      </span>
                                                </Spinner>
                                          )}
                                    </Button>
                              )}
                        </Modal.Footer>
                  </Modal>
            </>
      );
};

export default DeleteButton;
