import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import $ from "jquery";
import { toast } from "react-hot-toast";
import { Form } from "reactstrap";
import Sidebar from "../../components/dashboard/Sidebar";
import ProfileNavigation from "../../components/profile/ProfileNavigation";
import UserContext from "../../stores/UserContext";
import { FormButton } from "../../components/form";

const SellerLogo = () => {
      const userCtx = useContext(UserContext);
      const [logo, setLogo] = useState("");
      const [imageUrl, setImageUrl] = useState();
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/logo/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setImageUrl(response.data.seller.logo);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const [isLoading, setIsLoading] = useState(false);

      const handleImage = (file) => {
            setLogo(file);
            setImageUrl(URL.createObjectURL(file));
      };

      async function handleFormSubmit(event) {
            setIsLoading(true);
            event.preventDefault();
            $("button[type=submit]").prop("disabled", true);

            const data = {
                  logo: logo,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/logo/details`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }

                              if (response.data.result == "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (response.data.message.logo) {
                                                toast.error(
                                                      response.data.message.logo
                                                );
                                          }
                                    }
                              }
                              $("button[type=submit]").prop("disabled", false);
                              setIsLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard d-flex mb-0">
                                    <ProfileNavigation />

                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">Seller Logo</h5>
                                          <Form onSubmit={handleFormSubmit}>
                                                <section className="position-relative">
                                                      <label className="form__group-label">
                                                            Logo{" "}
                                                            <span className="text-danger">
                                                                  *
                                                            </span>{" "}
                                                      </label>
                                                      <div
                                                            role="button"
                                                            tabIndex="0"
                                                            className="form__group-upload"
                                                      >
                                                            <input
                                                                  accept="image/*"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        handleImage(
                                                                              event
                                                                                    .target
                                                                                    .files[0]
                                                                        )
                                                                  }
                                                                  type="file"
                                                                  autoComplete="off"
                                                                  tabIndex="-1"
                                                                  className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                            />
                                                            <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="41px"
                                                                  height="30px"
                                                                  viewBox="0 0 40.909 30"
                                                                  className="text-muted-light"
                                                            >
                                                                  <g transform="translate(0 -73.091)">
                                                                        <path
                                                                              data-name="Path 2125"
                                                                              d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                              transform="translate(0)"
                                                                              fill="currentColor"
                                                                        ></path>
                                                                  </g>
                                                            </svg>
                                                            <p className="form__group-description">
                                                                  <span className="text-accent font-semibold">
                                                                        Upload
                                                                        an image{" "}
                                                                  </span>
                                                                  or drag and
                                                                  drop <br />
                                                                  <span className="form__group-drag">
                                                                        PNG, JPG
                                                                  </span>
                                                            </p>
                                                      </div>
                                                      {imageUrl && (
                                                            <img
                                                                  src={imageUrl}
                                                                  className="rounded mt-3 border p-1"
                                                                  style={{
                                                                        height: "60px",
                                                                        width: "60px",
                                                                        position: "absolute",
                                                                        bottom: "10px",
                                                                        left: "10px",
                                                                        objectFit:
                                                                              "contain",
                                                                  }}
                                                            />
                                                      )}
                                                </section>
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default SellerLogo;
