import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import UserContext from "../../stores/UserContext";
import { Card, Table } from "react-bootstrap";
import {
      CardBody,
      CardHeader,
      DeleteButton,
      LinkButton,
} from "../../components/commons";
import { CancelIcon, MessageIcon } from "../../icons";

const BrandRequestList = () => {
      const [brands, setBrands] = useState([]);
      const userCtx = useContext(UserContext);

      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brand_requests`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands(response.data.brands);
                        }
                        if (response.data.result === "error") {
                              toast.error("Something weng wrong");
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleCancel = async (item) => {
            setLoadingData(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brand_requests/${item.slug}/cancel`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands([]);
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <Card>
                                                            <CardHeader title="Brand Request">
                                                                  <LinkButton
                                                                        link="/brands/requests/create"
                                                                        title="New Request"
                                                                  />
                                                                  <LinkButton
                                                                        link="/brands"
                                                                        title="Back"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Table
                                                                        bordered
                                                                        className="table-responsive table-hover align-items-center"
                                                                  >
                                                                        <thead>
                                                                              <tr>
                                                                                    <th>
                                                                                          S.N
                                                                                    </th>
                                                                                    <th>
                                                                                          Title
                                                                                    </th>
                                                                                    <th>
                                                                                          Requested
                                                                                          Date
                                                                                    </th>
                                                                                    <th>
                                                                                          Status
                                                                                    </th>
                                                                                    <th>
                                                                                          Action
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {brands.length >
                                                                              0 ? (
                                                                                    brands.map(
                                                                                          (
                                                                                                promotion,
                                                                                                index
                                                                                          ) => (
                                                                                                <tr
                                                                                                      key={
                                                                                                            index
                                                                                                      }
                                                                                                >
                                                                                                      <td>
                                                                                                            {
                                                                                                                  ++index
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div className="d-flex">
                                                                                                                  <span>
                                                                                                                        <div className="d-flex align-items-center">
                                                                                                                              {promotion.image && (
                                                                                                                                    <div className="flex-shrink-0 me-3">
                                                                                                                                          <div className="avatar-sm bg-light rounded p-1 h-100">
                                                                                                                                                <img
                                                                                                                                                      src={
                                                                                                                                                            promotion.image
                                                                                                                                                      }
                                                                                                                                                      alt={
                                                                                                                                                            promotion.name
                                                                                                                                                      }
                                                                                                                                                      className="img-fluid d-block"
                                                                                                                                                />
                                                                                                                                          </div>
                                                                                                                                    </div>
                                                                                                                              )}
                                                                                                                              <div className="flex-grow-2">
                                                                                                                                    <h5 className="fs-6 mb-1">
                                                                                                                                          <a
                                                                                                                                                className="text-dark"
                                                                                                                                                target="_blank"
                                                                                                                                          >
                                                                                                                                                {
                                                                                                                                                      promotion.title
                                                                                                                                                }
                                                                                                                                          </a>
                                                                                                                                    </h5>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </span>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  promotion.date
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  promotion.status
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td
                                                                                                            style={{
                                                                                                                  width: "100px",
                                                                                                            }}
                                                                                                      >
                                                                                                            {promotion.status ===
                                                                                                                  "Pending" && (
                                                                                                                  <DeleteButton
                                                                                                                        item={
                                                                                                                              promotion
                                                                                                                        }
                                                                                                                        handleDelete={
                                                                                                                              handleCancel
                                                                                                                        }
                                                                                                                        setLoading={
                                                                                                                              setLoadingData
                                                                                                                        }
                                                                                                                        loading={
                                                                                                                              loadingData
                                                                                                                        }
                                                                                                                        description="Do you really want to cancel these records? This process cannot be undone."
                                                                                                                        icon={
                                                                                                                              <CancelIcon />
                                                                                                                        }
                                                                                                                  />
                                                                                                            )}
                                                                                                            {promotion.status ===
                                                                                                                  "Rejected" && (
                                                                                                                  <DeleteButton
                                                                                                                        item={
                                                                                                                              promotion
                                                                                                                        }
                                                                                                                        handleDelete={
                                                                                                                              handleCancel
                                                                                                                        }
                                                                                                                        setLoading={
                                                                                                                              setLoadingData
                                                                                                                        }
                                                                                                                        loading={
                                                                                                                              loadingData
                                                                                                                        }
                                                                                                                        form={
                                                                                                                              true
                                                                                                                        }
                                                                                                                        classes="ms-2"
                                                                                                                        title="Message from admin"
                                                                                                                        description={
                                                                                                                              promotion.note
                                                                                                                        }
                                                                                                                        icon={
                                                                                                                              <MessageIcon color="green" />
                                                                                                                        }
                                                                                                                  />
                                                                                                            )}
                                                                                                      </td>
                                                                                                </tr>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <tr className="text-center">
                                                                                          <td
                                                                                                colSpan={
                                                                                                      9
                                                                                                }
                                                                                          >
                                                                                                No
                                                                                                request
                                                                                                found
                                                                                          </td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </Table>
                                                            </CardBody>
                                                      </Card>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BrandRequestList;
