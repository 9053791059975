import styled from "styled-components";

export const QuoteCountBox = styled.a`
      display: flex;
      align-items: center;
      border: 2px solid ${(props) => props.theme.black};
      border-radius: 5px;
      color: ${(props) => props.theme.black};
      background-color: ${(props) => props.theme.black};

      div {
            padding: 5px 16px;
            background-color: ${(props) => props.theme.white};
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
      }

      div:last-child {
            border-left: 2px solid ${(props) => props.theme.black};
            height: 34px;
            background-color: ${(props) => props.theme.black};
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            span {
                  color: ${(props) => props.theme.white};
            }
      }
`;

export const ProductListItems = styled.div`
      background: white;
      padding: 10px 20px;
      margin-bottom: 20px;
`;

export const ProductListTitle = styled.div`
      h2 {
            border-bottom: 1px dotted #000000;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
      }
`;

export const ProductListContent = styled.div`
      max-height: 286px;
      overflow: hidden auto;
      padding: 10px 0;
      overflow-x: hidden !important;

      &::-webkit-scrollbar {
            width: 6px;
      }

      &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
            transition: background 0.3s ease-in-out;
      }

      &::-webkit-scrollbar-thumb:hover {
            background: #555;
      }
`;
