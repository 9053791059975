import styled from "styled-components";

export const SignupContainer = styled.div`
      padding: 3rem;
      padding-top: 0;
`;

export const BrandItem = styled.div`
      padding: 2px;
      background-color: white;
      border-radius: 5px;

      img {
            border-radius: 5px;
      }
`;
