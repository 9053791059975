import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import ImageGallery from "../../components/brand/ImageGallery";
import Sidebar from "../../components/dashboard/Sidebar";
import { Header } from "../../layouts/Header";

const ImageList = () => {
      const [images, setImages] = useState([]);
      const user = JSON.parse(localStorage.getItem("userData"));

      useEffect(() => {
            const data = {
                  email: user.email,
            };
            axios.post(
                  "https://goworkbiz.infinityinfosys.com/api/images/gallery",
                  data
            ).then((response) => {
                  if (response.data.result === "success") {
                        setImages(response.data.galleries);
                  }
                  if (response.data.result === "error") {
                        toast.error(response.data.message);
                  }
            });
      }, []);
      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <Header
                                                      title="Image Gallery"
                                                      link="/images/galleries/create"
                                                      linkTitle="Add Gallery"
                                                />
                                                <div className="col-md-12">
                                                      <ImageGallery
                                                            images={images}
                                                      />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ImageList;
