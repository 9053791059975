import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import UserContext from "../../stores/UserContext";
import { Card, Col, Form, Table } from "react-bootstrap";
import { CardBody, CardHeader, LinkButton } from "../../components/commons";
import BrandManage from "./BrandManage";

const BrandList = () => {
      const [brands, setBrands] = useState([]);
      const userCtx = useContext(UserContext);

      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brands`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBrands(response.data.brands);
                        }
                        if (response.data.result === "error") {
                              toast.error("Something weng wrong");
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      async function handleProductPrice(item) {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brands/${item.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <div className="row">
                                                <Col md={12} className="mb-4">
                                                      <BrandManage
                                                            loading={loading}
                                                            totalProducts={0}
                                                      />
                                                </Col>
                                                <div className="col-md-12">
                                                      <Card>
                                                            <CardHeader title="Authorised Brand">
                                                                  <LinkButton
                                                                        link="/brands/requests/create"
                                                                        title="New Request"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Table
                                                                        bordered
                                                                        className="table-responsive table-hover align-items-center"
                                                                  >
                                                                        <thead>
                                                                              <tr>
                                                                                    <th>
                                                                                          S.N
                                                                                    </th>
                                                                                    <th>
                                                                                          Title
                                                                                    </th>
                                                                                    <th>
                                                                                          Products
                                                                                    </th>
                                                                                    <th>
                                                                                          Status
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {brands.length >
                                                                              0 ? (
                                                                                    brands.map(
                                                                                          (
                                                                                                promotion,
                                                                                                index
                                                                                          ) => (
                                                                                                <tr
                                                                                                      key={
                                                                                                            index
                                                                                                      }
                                                                                                >
                                                                                                      <td>
                                                                                                            {
                                                                                                                  ++index
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div className="d-flex">
                                                                                                                  <span>
                                                                                                                        <div className="d-flex align-items-center">
                                                                                                                              {promotion.image && (
                                                                                                                                    <div className="flex-shrink-0 me-3">
                                                                                                                                          <div className="avatar-sm bg-light rounded p-1 h-100">
                                                                                                                                                <img
                                                                                                                                                      src={
                                                                                                                                                            promotion.image
                                                                                                                                                      }
                                                                                                                                                      alt={
                                                                                                                                                            promotion.name
                                                                                                                                                      }
                                                                                                                                                      className="img-fluid d-block"
                                                                                                                                                />
                                                                                                                                          </div>
                                                                                                                                    </div>
                                                                                                                              )}
                                                                                                                              <div className="flex-grow-2">
                                                                                                                                    <h5 className="fs-6 mb-1">
                                                                                                                                          <a
                                                                                                                                                className="text-dark"
                                                                                                                                                target="_blank"
                                                                                                                                          >
                                                                                                                                                {
                                                                                                                                                      promotion.title
                                                                                                                                                }
                                                                                                                                          </a>
                                                                                                                                    </h5>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </span>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            {
                                                                                                                  promotion.products
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <Form.Check
                                                                                                                  type="switch"
                                                                                                                  id="custom-switch"
                                                                                                                  defaultChecked={
                                                                                                                        promotion.status
                                                                                                                  }
                                                                                                                  onBlur={() =>
                                                                                                                        handleProductPrice(
                                                                                                                              promotion
                                                                                                                        )
                                                                                                                  }
                                                                                                            />
                                                                                                      </td>
                                                                                                </tr>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <tr className="text-center">
                                                                                          <td
                                                                                                colSpan={
                                                                                                      9
                                                                                                }
                                                                                          >
                                                                                                No
                                                                                                product
                                                                                                found
                                                                                          </td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </Table>
                                                            </CardBody>
                                                      </Card>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BrandList;
