import React, { useState, useContext } from "react";
import Sidebar from "../../components/dashboard/Sidebar";

import $ from "jquery";
import axios from "axios";
import { toast } from "react-hot-toast";
import FormTitle from "../../components/form/FormTitle";
import FormInput from "../../components/form/FormInput";
import UserContext from "../../stores/UserContext";
import { Col, Row } from "react-bootstrap";
import { FormButton } from "../../components/form";

const BrandRequest = () => {
      const userCtx = useContext(UserContext);
      const [loading, setLoading] = useState(false);

      const [title, setTitle] = useState("");
      const [image, setImage] = useState("");
      const [document, setDocument] = useState("");
      const [documentUrl, setDocumentUrl] = useState("");
      const [imageUrl, setImageUrl] = useState("");

      const handleImage = (file) => {
            setImage(file);
            setImageUrl(URL.createObjectURL(file));
      };

      const handleDocument = (file) => {
            setDocument(file);
            setDocumentUrl(URL.createObjectURL(file));
      };

      async function handleFormSubmit(event) {
            event.preventDefault();
            setLoading(true);

            const data = {
                  title: title,
                  image: image,
                  document: document,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/brand_requests`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    $("form").trigger("reset");
                                    setImageUrl("");
                                    setDocumentUrl("");
                                    toast.success(response.data.message);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message.title) {
                                          toast.error(
                                                response.data.message.title
                                          );
                                    }
                                    if (response.data.message.image) {
                                          toast.error(
                                                response.data.message.image
                                          );
                                    }
                              }
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <div className="container-fluid">
                                          <Row>
                                                <Col md={12}>
                                                      <div className="form__header">
                                                            <h1 className="form__header-title">
                                                                  Request New
                                                                  Brand
                                                            </h1>
                                                      </div>
                                                      <form
                                                            onSubmit={
                                                                  handleFormSubmit
                                                            }
                                                      >
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Description"
                                                                        summary="Add your request brand details from here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <FormInput
                                                                              label="Title"
                                                                              classes="mb-3"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              type="text"
                                                                              handleInput={
                                                                                    setTitle
                                                                              }
                                                                        />
                                                                  </div>
                                                            </div>
                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Sample Image"
                                                                        summary="Add your brand sample image here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <section className="position-relative">
                                                                              <div
                                                                                    role="button"
                                                                                    tabIndex="0"
                                                                                    className="form__group-upload"
                                                                              >
                                                                                    <input
                                                                                          accept="image/*"
                                                                                          onChange={(
                                                                                                event
                                                                                          ) =>
                                                                                                handleImage(
                                                                                                      event
                                                                                                            .target
                                                                                                            .files[0]
                                                                                                )
                                                                                          }
                                                                                          type="file"
                                                                                          autoComplete="off"
                                                                                          tabIndex="-1"
                                                                                          className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                                                    />
                                                                                    <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          width="41px"
                                                                                          height="30px"
                                                                                          viewBox="0 0 40.909 30"
                                                                                          className="text-muted-light"
                                                                                    >
                                                                                          <g transform="translate(0 -73.091)">
                                                                                                <path
                                                                                                      data-name="Path 2125"
                                                                                                      d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                                                      transform="translate(0)"
                                                                                                      fill="currentColor"
                                                                                                ></path>
                                                                                          </g>
                                                                                    </svg>
                                                                                    <p className="form__group-description">
                                                                                          <span className="text-accent font-semibold">
                                                                                                Upload
                                                                                                an
                                                                                                image
                                                                                                or
                                                                                                drag
                                                                                                and
                                                                                                drop{" "}
                                                                                          </span>
                                                                                          <br />
                                                                                          <span className="form__group-drag">
                                                                                                PNG,
                                                                                                JPG
                                                                                          </span>
                                                                                    </p>
                                                                              </div>
                                                                        </section>
                                                                        {imageUrl && (
                                                                              <img
                                                                                    src={
                                                                                          imageUrl
                                                                                    }
                                                                                    className="rounded mt-3 border p-1"
                                                                                    style={{
                                                                                          height: "60px",
                                                                                          width: "60px",
                                                                                    }}
                                                                              />
                                                                        )}
                                                                  </div>
                                                            </div>

                                                            <div className="form__group">
                                                                  <FormTitle
                                                                        title="Sample Image"
                                                                        summary="Add your authorized document image here"
                                                                  />
                                                                  <div className="form__group-body bg-white">
                                                                        <section className="position-relative">
                                                                              <div
                                                                                    role="button"
                                                                                    tabIndex="0"
                                                                                    className="form__group-upload"
                                                                              >
                                                                                    <input
                                                                                          accept="image/*"
                                                                                          onChange={(
                                                                                                event
                                                                                          ) =>
                                                                                                handleDocument(
                                                                                                      event
                                                                                                            .target
                                                                                                            .files[0]
                                                                                                )
                                                                                          }
                                                                                          type="file"
                                                                                          autoComplete="off"
                                                                                          tabIndex="-1"
                                                                                          className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                                                    />
                                                                                    <svg
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          width="41px"
                                                                                          height="30px"
                                                                                          viewBox="0 0 40.909 30"
                                                                                          className="text-muted-light"
                                                                                    >
                                                                                          <g transform="translate(0 -73.091)">
                                                                                                <path
                                                                                                      data-name="Path 2125"
                                                                                                      d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                                                      transform="translate(0)"
                                                                                                      fill="currentColor"
                                                                                                ></path>
                                                                                          </g>
                                                                                    </svg>
                                                                                    <p className="form__group-description">
                                                                                          <span className="text-accent font-semibold">
                                                                                                Upload
                                                                                                an
                                                                                                image
                                                                                                or
                                                                                                drag
                                                                                                and
                                                                                                drop{" "}
                                                                                          </span>
                                                                                          <br />
                                                                                          <span className="form__group-drag">
                                                                                                PNG,
                                                                                                JPG
                                                                                          </span>
                                                                                    </p>
                                                                              </div>
                                                                        </section>
                                                                        {documentUrl && (
                                                                              <img
                                                                                    src={
                                                                                          documentUrl
                                                                                    }
                                                                                    className="rounded mt-3 border p-1"
                                                                                    style={{
                                                                                          height: "60px",
                                                                                          width: "60px",
                                                                                    }}
                                                                              />
                                                                        )}
                                                                  </div>
                                                            </div>

                                                            <div className="mb-4 text-end">
                                                                  <FormButton
                                                                        loading={
                                                                              loading
                                                                        }
                                                                  />
                                                            </div>
                                                      </form>
                                                </Col>
                                          </Row>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BrandRequest;
