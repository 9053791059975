import React from "react";

const ImageGallery = ({ images }) => {
      return (
            <>
                  {images &&
                        images.map((image, index) => (
                              <div
                                    className="col-xl-2 col-md-3 col-sm-3"
                                    key={index}
                              >
                                    <div className="card card-animate text-center">
                                          <div className="card-body">
                                                <img
                                                      src={image.image}
                                                      style={{
                                                            height: "110px",
                                                            width: "110px",
                                                      }}
                                                />
                                          </div>
                                    </div>
                              </div>
                        ))}
            </>
      );
};

export default ImageGallery;
