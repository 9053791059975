import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import $ from "jquery";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";
import FormFile from "../../components/form/FormFile";
import FormInput from "../../components/form/FormInput";
import ProfileNavigation from "../../components/profile/ProfileNavigation";
import UserContext from "../../stores/UserContext";
import { FormButton } from "../../components/form";

const BankInformation = () => {
      const userCtx = useContext(UserContext);

      const [bankTitle, setBankTitle] = useState("");
      const [accountNumber, setAccountNumber] = useState("");
      const [bankName, setBankName] = useState("");
      const [bankBranch, setBankBranch] = useState("");
      const [bankCheque, setBankCheque] = useState("");
      const [imageUrl, setImageUrl] = useState("");

      const [isLoading, setIsLoading] = useState(false);

      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/bank/details`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBankName(response.data.seller.bank_name);
                              setBankTitle(response.data.seller.bank_title);
                              setBankBranch(response.data.seller.bank_branch);
                              setAccountNumber(
                                    response.data.seller.bank_account
                              );
                              setImageUrl(response.data.seller.bank_cheque);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setIsLoading(true);
            event.preventDefault();

            const data = {
                  account_title: bankTitle,
                  account_number: accountNumber,
                  bank_name: bankName,
                  bank_branch: bankBranch,
                  bank_cheque: bankCheque,
            };
            $("button[type=submit]").prop("disabled", true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/bank/details`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                                    if (response.data.message) {
                                          if (
                                                response.data.message
                                                      .account_title
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .account_title
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .account_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .account_number
                                                );
                                          }
                                          if (response.data.message.bank_name) {
                                                toast.error(
                                                      response.data.message
                                                            .bank_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .bank_branch
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .bank_branch
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .bank_cheque
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .bank_cheque
                                                );
                                          }
                                    }
                              }
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              setIsLoading(false);
                              $("button[type=submit]").prop("disabled", false);
                        }, 3000);
                  });
      };

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header profile-navigation">
                              <div className="main-dashboard bg-white d-flex mb-0">
                                    <ProfileNavigation />
                                    <div className="profile__sidebar-content">
                                          <h5 className="mb-3">Bank Account</h5>
                                          <Form onSubmit={handleFormSubmit}>
                                                <FormInput
                                                      label="Account Title"
                                                      required={true}
                                                      type="text"
                                                      value={bankTitle}
                                                      handleInput={setBankTitle}
                                                      classes="mb-3"
                                                />
                                                <FormInput
                                                      label="Account Number"
                                                      required={true}
                                                      type="text"
                                                      value={accountNumber}
                                                      handleInput={
                                                            setAccountNumber
                                                      }
                                                      classes="mb-3"
                                                />
                                                <FormInput
                                                      label="Bank Name"
                                                      required={true}
                                                      type="text"
                                                      value={bankName}
                                                      handleInput={setBankName}
                                                      classes="mb-3"
                                                />

                                                <FormInput
                                                      label="Bank Branch"
                                                      required={true}
                                                      handleInput={
                                                            setBankBranch
                                                      }
                                                      type="text"
                                                      value={bankBranch}
                                                      classes="mb-3"
                                                />
                                                <FormFile
                                                      classes="position-relative"
                                                      handleImage={
                                                            setBankCheque
                                                      }
                                                      title="Bank Cheque"
                                                      required={true}
                                                      imageUrl={imageUrl}
                                                      handleImageUrl={
                                                            setImageUrl
                                                      }
                                                />
                                                <div className="mt-4 text-start">
                                                      <FormButton
                                                            loading={isLoading}
                                                      />
                                                </div>
                                          </Form>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BankInformation;
