import React from "react";

const FormSelect = ({
      classes,
      label,
      required,
      handleSelect,
      data,
      value,
}) => {
      return (
            <>
                  <div className={classes}>
                        <label className="form__group-status">
                              {label}{" "}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </label>
                        <div className="css-b62m3t-container">
                              <select
                                    value={value}
                                    onChange={(event) =>
                                          handleSelect(event.target.value)
                                    }
                                    required={required}
                                    className="form-select"
                              >
                                    <option value="">Select One</option>
                                    {data.map((brand, index) => (
                                          <option value={brand.id} key={index}>
                                                {brand.title}
                                          </option>
                                    ))}
                              </select>
                        </div>
                  </div>
            </>
      );
};

export default FormSelect;
