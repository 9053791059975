import React, { useState, useEffect } from "react";
import Sidebar from "../../components/dashboard/Sidebar";

import axios from "axios";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import FormTitle from "../../components/form/FormTitle";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
import Progress from "react-progress-2";

const options = [
      {
            slug: "price",
            name: "Price",
      },
      {
            slug: "on_demand",
            name: "On Demand",
      },
      {
            slug: "on_request",
            name: "On Request",
      },
];

const ProductEdit = () => {
      const { productId } = useParams();
      const user = JSON.parse(localStorage.getItem("userData"));
      const [product, setProduct] = useState([]);

      const [brands, setBrands] = useState([]);
      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);

      const handleImage = (file) => {
            setImage(file);
            setImageUrl(URL.createObjectURL(file));
      };

      function handleCategories() {
            axios.post(
                  "https://goworkbiz.infinityinfosys.com/api/seller/brands",
                  { email: user.email }
            ).then((response) => {
                  if (response.data.result === "success") {
                        setBrands(response.data.brands);
                  }
                  if (response.data.result === "error") {
                        toast.error(response.data.message);
                  }
            });
            axios.post(
                  "https://goworkbiz.infinityinfosys.com/api/seller/categories",
                  { shop_code: 22280 }
            ).then((response) => {
                  if (response.data.result === "success") {
                        setCategories(response.data.categories);
                  }
                  if (response.data.result === "error") {
                        toast.error(response.data.message);
                  }
            });
      }

      useEffect(() => {
            Progress.show();
            handleCategories();
            axios.get(
                  `https://goworkbiz.infinityinfosys.com/api/seller/products/${productId}?email=${user.email}`
            ).then((response) => {
                  if (response.data.result === "success") {
                        setProduct(response.data.product);
                        setCategory(response.data.product.parent_category);
                        setSelectedCategory(
                              response.data.product.category_slug
                        );
                        if (response.data.product.subcategories) {
                              setSubCategory(response.data.product.subcategory);
                              setSubCategories(
                                    response.data.product.subcategories
                              );
                        }
                        if (response.data.product.childcategories) {
                              setChildCategory(
                                    response.data.product.childcategory
                              );
                              setChildCategories(
                                    response.data.product.childcategories
                              );
                        }
                  }
                  if (response.data.result === "error") {
                        toast.error(response.data.message);
                  }
            });
            Progress.hide();
      }, [productId]);

      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState(product.image);
      const [brand, setBrand] = useState(product.brand);
      const [category, setCategory] = useState(product.category);
      const [subCategory, setSubCategory] = useState(product.subcategory);
      const [childCategory, setChildCategory] = useState(product.childcategory);
      const [productName, setProductName] = useState(product.product_name);
      const [price, setPrice] = useState(product.price);
      const [unit, setUnit] = useState(product.unit);
      const [quantity, setQuantity] = useState(product.quantity);
      const [priceType, setPriceType] = useState(product.price_type);
      const [dealerPrice, setDealerPrice] = useState(product.dealer_price);
      const [sellerPrice, setSellerPrice] = useState(product.seller_price);
      const [description, setDescription] = useState(product.description);
      const [selectedCategory, setSelectedCategory] = useState(
            product.category_slug
      );

      function handleSubCatgories(value) {
            setCategory(value);
            setSelectedCategory(value);
            setSubCategories([]);
            setChildCategories([]);
            axios.get(
                  `https://goworkbiz.infinityinfosys.com/api/seller/categories/${value}`
            ).then((response) => {
                  if (response.data.result === "success") {
                        setSubCategories(response.data.categories);
                  }
                  if (response.data.result === "error") {
                        toast.error(response.data.message);
                  }
            });
      }

      function handleChildCatgories(value) {
            setSubCategory(value);
            setSelectedCategory(value);
            setChildCategories([]);

            axios.get(
                  `https://goworkbiz.infinityinfosys.com/api/seller/categories/${value}`
            ).then((response) => {
                  if (response.data.result === "success") {
                        setChildCategories(response.data.categories);
                  }
                  if (response.data.result === "error") {
                        toast.error(response.data.message);
                  }
            });
      }

      function handleFormSubmit(event) {
            event.preventDefault();
            const data = {
                  product_name: productName
                        ? productName
                        : product.product_name,
                  brand: brand ? brand : product.brand,
                  category: selectedCategory,
                  unit: unit ? unit : product.unit,
                  description: description ? description : product.description,
                  price: price ? price : product.price,
                  price_type: priceType ? priceType : product.price_type,
                  dealer_price: dealerPrice
                        ? dealerPrice
                        : product.dealer_price,
                  seller_price: sellerPrice
                        ? sellerPrice
                        : product.seller_price,
                  quantity: quantity ? quantity : product.quantity,
                  image: image,
                  email: user.email,
                  shop_code: 22280,
            };

            if (data) {
                  axios.post(
                        `https://goworkbiz.infinityinfosys.com/api/seller/products/${productId}/update`,
                        data,
                        {
                              headers: {
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  ).then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              // setImageUrl('');

                              // handleCategories()
                              // setSubCategories([]);
                              // setChildCategories([]);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message["product_name"]) {
                                    toast.error(
                                          response.data.message["product_name"]
                                    );
                              }
                              if (response.data.message["brand"]) {
                                    toast.error(response.data.message["brand"]);
                              }
                              if (response.data.message["category"]) {
                                    toast.error(
                                          response.data.message["category"]
                                    );
                              }
                              if (response.data.message["unit"]) {
                                    toast.error(response.data.message["unit"]);
                              }
                              if (response.data.message["description"]) {
                                    toast.error(
                                          response.data.message["description"]
                                    );
                              }
                              if (response.data.message["price"]) {
                                    toast.error(response.data.message["price"]);
                              }
                              if (response.data.message["price_type"]) {
                                    toast.error(
                                          response.data.message["price_type"]
                                    );
                              }
                              if (response.data.message["dealer_price"]) {
                                    toast.error(
                                          response.data.message["dealer_price"]
                                    );
                              }
                              if (response.data.message["seller_price"]) {
                                    toast.error(
                                          response.data.message["seller_price"]
                                    );
                              }
                              if (response.data.message["quantity"]) {
                                    toast.error(
                                          response.data.message["quantity"]
                                    );
                              }
                              if (response.data.message["image"]) {
                                    toast.error(response.data.message["image"]);
                              }
                              if (response.data.errorMessage) {
                                    toast.error(response.data.errorMessage);
                              }
                        }
                  });
            }
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="container-fluid">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="form__header">
                                                      <h1 className="form__header-title">
                                                            Create New Product
                                                      </h1>
                                                </div>
                                                <form
                                                      onSubmit={
                                                            handleFormSubmit
                                                      }
                                                >
                                                      <div className="form__group">
                                                            <FormTitle
                                                                  title="Featured Image"
                                                                  summary="Upload your product featured image here"
                                                            />
                                                            <div className="form__group-body bg-white">
                                                                  <section className="position-relative">
                                                                        <div
                                                                              role="button"
                                                                              tabIndex="0"
                                                                              className="form__group-upload"
                                                                        >
                                                                              <input
                                                                                    accept="image/*"
                                                                                    onChange={(
                                                                                          event
                                                                                    ) =>
                                                                                          handleImage(
                                                                                                event
                                                                                                      .target
                                                                                                      .files[0]
                                                                                          )
                                                                                    }
                                                                                    type="file"
                                                                                    autoComplete="off"
                                                                                    tabIndex="-1"
                                                                                    className="position-absolute top-0 end-0 bottom-0 start-0 overflow-hidden opacity-0"
                                                                              />
                                                                              <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="41px"
                                                                                    height="30px"
                                                                                    viewBox="0 0 40.909 30"
                                                                                    className="text-muted-light"
                                                                              >
                                                                                    <g transform="translate(0 -73.091)">
                                                                                          <path
                                                                                                data-name="Path 2125"
                                                                                                d="M39.129,89.827A8.064,8.064,0,0,0,34.58,86.94,5.446,5.446,0,0,0,30,78.546a5.207,5.207,0,0,0-3.537,1.321,10.921,10.921,0,0,0-10.1-6.776,10.511,10.511,0,0,0-7.713,3.2A10.508,10.508,0,0,0,5.454,84q0,.277.043.916A9.528,9.528,0,0,0,0,93.546a9.193,9.193,0,0,0,2.8,6.743,9.191,9.191,0,0,0,6.744,2.8H32.728a8.172,8.172,0,0,0,6.4-13.264Zm-12.06-.575a.656.656,0,0,1-.479.2H21.818v7.5a.691.691,0,0,1-.681.681H17.045a.691.691,0,0,1-.682-.681v-7.5H11.59a.655.655,0,0,1-.681-.681.8.8,0,0,1,.213-.512L18.6,80.783a.722.722,0,0,1,.98,0l7.5,7.5a.663.663,0,0,1,.191.49A.656.656,0,0,1,27.07,89.252Z"
                                                                                                transform="translate(0)"
                                                                                                fill="currentColor"
                                                                                          ></path>
                                                                                    </g>
                                                                              </svg>
                                                                              <p className="form__group-description">
                                                                                    <span className="text-accent font-semibold">
                                                                                          Upload
                                                                                          an
                                                                                          image
                                                                                    </span>
                                                                                    or
                                                                                    drag
                                                                                    and
                                                                                    drop{" "}
                                                                                    <br />
                                                                                    <span className="form__group-drag">
                                                                                          PNG,
                                                                                          JPG
                                                                                    </span>
                                                                              </p>
                                                                        </div>
                                                                  </section>
                                                                  {(imageUrl ||
                                                                        product.image) && (
                                                                        <img
                                                                              src={
                                                                                    imageUrl
                                                                                          ? imageUrl
                                                                                          : product.image
                                                                              }
                                                                              className="rounded mt-3 border p-1"
                                                                              style={{
                                                                                    height: "60px",
                                                                                    width: "60px",
                                                                              }}
                                                                        />
                                                                  )}
                                                            </div>
                                                      </div>
                                                      <div className="form__group">
                                                            <FormTitle
                                                                  title="Brand &amp; Categories"
                                                                  summary="Select product brand and categories from here"
                                                            />
                                                            <div className="form__group-body bg-white">
                                                                  <FormSelect
                                                                        classes="mb-4"
                                                                        label="Brand"
                                                                        required={
                                                                              true
                                                                        }
                                                                        value={
                                                                              product.brand
                                                                        }
                                                                        handleSelect={(
                                                                              event
                                                                        ) =>
                                                                              setBrand(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        data={
                                                                              brands
                                                                        }
                                                                  />

                                                                  <FormSelect
                                                                        classes="mb-4"
                                                                        label="Categories"
                                                                        value={
                                                                              category
                                                                        }
                                                                        required={
                                                                              true
                                                                        }
                                                                        handleSelect={(
                                                                              event
                                                                        ) =>
                                                                              handleSubCatgories(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        data={
                                                                              categories
                                                                        }
                                                                  />
                                                                  {subCategories.length >
                                                                        0 && (
                                                                        <FormSelect
                                                                              classes="mb-4"
                                                                              label="Sub Categories"
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    subCategory
                                                                              }
                                                                              handleSelect={(
                                                                                    event
                                                                              ) =>
                                                                                    handleChildCatgories(
                                                                                          event
                                                                                                .target
                                                                                                .value
                                                                                    )
                                                                              }
                                                                              data={
                                                                                    subCategories
                                                                              }
                                                                        />
                                                                  )}
                                                                  {childCategories.length >
                                                                        0 && (
                                                                        <FormSelect
                                                                              classes="mb-4"
                                                                              label="Child Categories"
                                                                              value={
                                                                                    childCategory
                                                                              }
                                                                              required={
                                                                                    true
                                                                              }
                                                                              handleSelect={(
                                                                                    event
                                                                              ) =>
                                                                                    setSelectedCategory(
                                                                                          event
                                                                                                .target
                                                                                                .value
                                                                                    )
                                                                              }
                                                                              data={
                                                                                    childCategories
                                                                              }
                                                                        />
                                                                  )}
                                                            </div>
                                                      </div>
                                                      <div className="form__group">
                                                            <FormTitle
                                                                  title="Description"
                                                                  summary="Add your product description and necessary information from here"
                                                            />
                                                            <div className="form__group-body bg-white">
                                                                  <FormInput
                                                                        label="Name"
                                                                        classes="mb-3"
                                                                        required={
                                                                              true
                                                                        }
                                                                        readOnlyValue={
                                                                              product.by_seller
                                                                        }
                                                                        value={
                                                                              product.product_name
                                                                        }
                                                                        type="text"
                                                                        handleInput={(
                                                                              event
                                                                        ) =>
                                                                              setProductName(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        label="Unit"
                                                                        classes="mb-3"
                                                                        required={
                                                                              true
                                                                        }
                                                                        value={
                                                                              product.unit
                                                                        }
                                                                        type="text"
                                                                        handleInput={(
                                                                              event
                                                                        ) =>
                                                                              setUnit(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                                  <div className="mb-4">
                                                                        <label className="form__group-label">
                                                                              Description
                                                                        </label>
                                                                        <textarea
                                                                              className="form__group-message"
                                                                              autoComplete="off"
                                                                              autoCorrect="off"
                                                                              autoCapitalize="off"
                                                                              spellCheck="false"
                                                                              rows="4"
                                                                              value={
                                                                                    product.description
                                                                              }
                                                                              onChange={(
                                                                                    event
                                                                              ) =>
                                                                                    setDescription(
                                                                                          event
                                                                                                .target
                                                                                                .value
                                                                                    )
                                                                              }
                                                                        ></textarea>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="form__group-information">
                                                            <FormTitle
                                                                  title="Simple Product Information"
                                                                  summary="Add your simple product description and necessary information from here"
                                                            />
                                                            <div className="form__group-body bg-white">
                                                                  <FormSelect
                                                                        classes="mb-3"
                                                                        label="Price Type"
                                                                        required={
                                                                              true
                                                                        }
                                                                        value={
                                                                              product.price_type
                                                                        }
                                                                        handleSelect={(
                                                                              event
                                                                        ) =>
                                                                              setPriceType(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                        data={
                                                                              options
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        label="Price"
                                                                        classes="mb-3"
                                                                        required={
                                                                              true
                                                                        }
                                                                        type="number"
                                                                        value={
                                                                              product.price
                                                                        }
                                                                        minValue="0"
                                                                        handleInput={(
                                                                              event
                                                                        ) =>
                                                                              setPrice(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        label="Dealer Percentage"
                                                                        classes="mb-3"
                                                                        required={
                                                                              true
                                                                        }
                                                                        type="number"
                                                                        value={
                                                                              product.dealer_price
                                                                        }
                                                                        minValue="0"
                                                                        handleInput={(
                                                                              event
                                                                        ) =>
                                                                              setDealerPrice(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        label="Selling Percentage"
                                                                        classes="mb-3"
                                                                        required={
                                                                              true
                                                                        }
                                                                        type="text"
                                                                        value={
                                                                              product.seller_price
                                                                        }
                                                                        handleInput={(
                                                                              event
                                                                        ) =>
                                                                              setSellerPrice(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        label="Quantity"
                                                                        classes="mb-3"
                                                                        required={
                                                                              true
                                                                        }
                                                                        type="text"
                                                                        value={
                                                                              product.quantity
                                                                        }
                                                                        handleInput={(
                                                                              event
                                                                        ) =>
                                                                              setQuantity(
                                                                                    event
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  />
                                                            </div>
                                                      </div>
                                                      <div className="mb-4 text-end">
                                                            <button
                                                                  data-variant="normal"
                                                                  className="form__group-btn bg-accent text-light"
                                                                  type="submit"
                                                            >
                                                                  Update Product
                                                            </button>
                                                      </div>
                                                </form>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductEdit;
