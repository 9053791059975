import React from "react";

import Login from "./auth/Login";
import SignUp from "./auth/SignUp";

import { Route, Routes } from "react-router-dom";

// Page List
import Dashboard from "./backend/dashboard/Dashboard";
import ProductList from "./backend/product/ProductList";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermCondition from "./pages/TermCondition";
import OrderList from "./backend/order/OrderList";
import NotificationList from "./backend/notification/NotificationList";
import ImageList from "./backend/gallery/ImageList";
import Documentation from "./backend/documentation/Documentation";
import ProfilePage from "./backend/profile/ProfilePage";
import {
      CouponList,
      CouponEdit,
      CouponCreate,
      CouponShow,
} from "./backend/coupons";
import ProductEdit from "./backend/product/ProductEdit";
import BrandList from "./backend/brands/BrandList";
import ImageCreate from "./backend/gallery/ImageCreate";
import { ContactBook, ContactCreate, ContactEdit } from "./backend/contacts";

import PromotionCreate from "./backend/promotion/PromotionCreate";
import PromotionEdit from "./backend/promotion/PromotionEdit";
import { SupportTicket, SupportCreate, SupportShow } from "./backend/supports";
import BrandRequest from "./backend/brands/BrandRequest";
import Help from "./backend/help/Help";
import PageNotFound from "./pages/PageNotFound";
import {
      SellerLogo,
      ProfileUpdate,
      BusinessInformation,
      ChangePassword,
} from "./backend/profile";
import BankInformation from "./backend/bank/BankInformation";
import { WarehouseAddress, ReturnAddress } from "./backend/address";
import InCharge from "./backend/incharge/InCharge";
import { ProductCheckout, ProductManage } from "./backend/products";
import { BrandRequestList } from "./backend/brand_requests";
import {
      ProductRequestCreate,
      ProductRequestEdit,
      ProductRequestList,
      ProductRequestPrice,
      ProductRequestShow,
} from "./backend/product_requests";
import ProductPrice from "./backend/product/ProductPrice";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/" exact element={<Login />} />
                        <Route path="/register" element={<SignUp />} />

                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />
                        <Route
                              path="/terms-and-condition"
                              element={<TermCondition />}
                        />

                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Brand List */}
                        <Route path="/brands" element={<BrandList />} />
                        <Route
                              path="/brands/requests/create"
                              element={<BrandRequest />}
                        />
                        <Route
                              path="/brands/requests"
                              element={<BrandRequestList />}
                        />

                        {/* Product */}
                        <Route path="/products" element={<ProductList />} />
                        <Route
                              path="/products/manage/price"
                              element={<ProductPrice />}
                        />
                        <Route
                              path="/products/:productId/edit"
                              element={<ProductEdit />}
                        />

                        {/* Product Manage */}
                        <Route
                              path="/products/manage"
                              element={<ProductManage />}
                        />
                        <Route
                              path="/products/manage/checkout"
                              element={<ProductCheckout />}
                        />

                        {/* Product Request */}
                        <Route
                              path="/product_requests"
                              element={<ProductRequestList />}
                        />
                        <Route
                              path="/product_requests/create"
                              element={<ProductRequestCreate />}
                        />
                        <Route
                              path="/product_requests/:productId/edit"
                              element={<ProductRequestEdit />}
                        />
                        <Route
                              path="/product_requests/:productId/show"
                              element={<ProductRequestShow />}
                        />
                        <Route
                              path="/product_requests/manage/price"
                              element={<ProductRequestPrice />}
                        />

                        {/* Contact Book */}
                        <Route
                              path="/contact/books"
                              element={<ContactBook />}
                        />
                        <Route
                              path="/contact/books/create"
                              element={<ContactCreate />}
                        />
                        <Route
                              path="/contact/books/:contactId/edit"
                              element={<ContactEdit />}
                        />

                        {/* Order List */}
                        <Route path="/orders" element={<OrderList />} />

                        {/* Notification Page */}
                        <Route
                              path="/notifications"
                              element={<NotificationList />}
                        />

                        {/* Image Gallery Page */}
                        <Route
                              path="/images/galleries"
                              element={<ImageList />}
                        />
                        <Route
                              path="/images/galleries/create"
                              element={<ImageCreate />}
                        />

                        {/* Documentation */}
                        <Route
                              path="/documentation"
                              element={<Documentation />}
                        />

                        {/* Promotion Page */}
                        <Route
                              path="/brands/promotions/create"
                              element={<PromotionCreate />}
                        />
                        <Route
                              path="/brands/promotions/:promotionId/edit"
                              element={<PromotionEdit />}
                        />

                        {/* Profile */}
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route
                              path="/profile/update"
                              element={<ProfileUpdate />}
                        />

                        {/* Seller Information */}
                        <Route
                              path="/business-information"
                              element={<BusinessInformation />}
                        />
                        <Route path="/seller-logo" element={<SellerLogo />} />

                        {/* Bank Information */}
                        <Route
                              path="/bank-account"
                              element={<BankInformation />}
                        />

                        {/* Change Password */}
                        <Route
                              path="/change-password"
                              element={<ChangePassword />}
                        />

                        {/* Person In Charge */}
                        <Route
                              path="/person-in-charge"
                              element={<InCharge />}
                        />

                        {/* Warehouse & Return Address */}
                        <Route
                              path="/return-address"
                              element={<ReturnAddress />}
                        />
                        <Route
                              path="/warehouse-address"
                              element={<WarehouseAddress />}
                        />

                        {/* Coupon */}
                        <Route path="/coupons" element={<CouponList />} />
                        <Route
                              path="/coupons/create"
                              element={<CouponCreate />}
                        />
                        <Route
                              path="/coupons/:couponId/edit"
                              element={<CouponEdit />}
                        />
                        <Route
                              path="/coupons/:couponId/show"
                              element={<CouponShow />}
                        />

                        {/* Support Ticket */}
                        <Route
                              path="/support-tickets"
                              element={<SupportTicket />}
                        />
                        <Route
                              path="/support-tickets/create"
                              element={<SupportCreate />}
                        />
                        <Route
                              path="/support-tickets/:ticketId/show"
                              element={<SupportShow />}
                        />

                        <Route path="/help" element={<Help />} />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
