import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";

import { toast } from "react-hot-toast";
import Sidebar from "../../components/dashboard/Sidebar";

import UserContext from "../../stores/UserContext";
import {
      Card,
      Container,
      Row,
      CardBody,
      ListGroupItem,
      ListGroup,
      Col,
} from "react-bootstrap";
import { CardHeader, LinkButton } from "../../components/commons";
import { useParams } from "react-router-dom";

const ProductRequestShow = () => {
      const { productId } = useParams();
      const userCtx = useContext(UserContext);

      const [product, setProduct] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/product_requests/${productId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProduct(response.data.product);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <Container fluid>
                                          <Row>
                                                <div className="col-md-12">
                                                      <Card>
                                                            <CardHeader title="Requested Product">
                                                                  <LinkButton
                                                                        link="/product_requests"
                                                                        title="Back"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Row>
                                                                        <Col
                                                                              lg={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <ListGroup>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Product
                                                                                                name:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.title
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Brand
                                                                                                name:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.brand_name
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Category
                                                                                                name:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.category_name
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                MRP
                                                                                                Price:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.mrp_price
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Dealer
                                                                                                %:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.dealer_price
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Retailer
                                                                                                Price:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.retailer_price
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Stock:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.quantity
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Unit:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.unit
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Price
                                                                                                Type:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.price_type
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Status:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.status
                                                                                          }
                                                                                    </ListGroupItem>
                                                                                    <ListGroupItem>
                                                                                          <strong>
                                                                                                Requested
                                                                                                Date:
                                                                                          </strong>{" "}
                                                                                          {
                                                                                                product.date
                                                                                          }
                                                                                    </ListGroupItem>
                                                                              </ListGroup>
                                                                        </Col>
                                                                        <Col
                                                                              lg={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <div className="p-4 border rounded">
                                                                                    <img
                                                                                          src={
                                                                                                product.image
                                                                                          }
                                                                                          alt=""
                                                                                          className="w-100"
                                                                                    />
                                                                                    <div className="mt-3">
                                                                                          {
                                                                                                product.description
                                                                                          }
                                                                                    </div>
                                                                              </div>
                                                                        </Col>
                                                                  </Row>
                                                            </CardBody>
                                                      </Card>
                                                </div>
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductRequestShow;
