import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
      root: {
            "&$checked": {
                  color: "#000",
            },
      },
      checked: {},
      wrap: {
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: 0,
      },
      label: {
            fontSize: "1rem",
      },
});

const BrandFilter = ({ brands, loading, changeChecked, type }) => {
      const classes = useStyles();

      return (
            <>
                  {!loading
                        ? brands.map((brand, index) => (
                                <FormControlLabel
                                      classes={{
                                            label: classes.label,
                                            root: classes.wrap,
                                      }}
                                      key={index}
                                      control={
                                            <Checkbox
                                                  classes={{
                                                        checked: classes.checked,
                                                        root: classes.root,
                                                  }}
                                                  size="small"
                                                  checked={brand.checked}
                                                  onChange={() =>
                                                        changeChecked(
                                                              brand.id,
                                                              type
                                                        )
                                                  }
                                                  inputProps={{
                                                        "aria-label":
                                                              "checkbox with small size",
                                                  }}
                                            />
                                      }
                                      // label={`${ brand.title } (${brand.products})`}
                                      label={`${brand.title}`}
                                />
                          ))
                        : ""}
            </>
      );
};

export default BrandFilter;
