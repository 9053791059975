import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";

import { toast } from "react-hot-toast";

import Progress from "react-progress-2";
import {
      Card,
      Col,
      Container,
      FormControl,
      Row,
      Spinner,
      Table,
} from "react-bootstrap";
import UserContext from "../../../stores/UserContext";
import Sidebar from "../../../components/dashboard/Sidebar";
import { CardBody, CardHeader, LinkButton } from "../../../components/commons";

const ProductPrice = () => {
      const userCtx = useContext(UserContext);

      const [products, setProducts] = useState([]);
      const [loading, setLoading] = useState(false);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [totalProducts, setTotalProducts] = useState(0);
      const [pageNumber, setPageNumber] = useState(1);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products`,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.errorMessage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      async function handleProductPrice(price, product, table) {
            const data = {
                  price: price,
                  table: table,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/seller/products/${product.slug}/price`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${userCtx?.token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }

      useEffect(() => {
            getProductData(pageNumber);
      }, [pageNumber]);

      async function getProductData(pageNumber) {
            setLoading(true);
            window.scrollTo(0, 0);
            Progress.show();
            setLoading(true);

            const url = `${process.env.REACT_APP_SECRET_KEY}/api/seller/products?page=${pageNumber}`;
            await axios
                  .get(url, {
                        headers: {
                              Authorization: `Bearer ${userCtx?.token}`,
                        },
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
            Progress.hide();
      }

      return (
            <>
                  <div className="d-flex">
                        <Sidebar />
                        <div className="header">
                              <div className="downbar">
                                    <Container fluid>
                                          <Row>
                                                <Col lg={12}>
                                                      <Card>
                                                            <CardHeader title="Product Price Manage ">
                                                                  <LinkButton
                                                                        title="Back"
                                                                        link="/products"
                                                                  />
                                                            </CardHeader>
                                                            <CardBody>
                                                                  <Table
                                                                        bordered
                                                                        hover
                                                                  >
                                                                        <thead>
                                                                              <tr>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "80px",
                                                                                          }}
                                                                                    >
                                                                                          S.N
                                                                                    </th>
                                                                                    <th>
                                                                                          Product
                                                                                          Name
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "160px",
                                                                                          }}
                                                                                    >
                                                                                          MRP
                                                                                          Price
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "140px",
                                                                                          }}
                                                                                    >
                                                                                          Retailer
                                                                                          Price
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "100px",
                                                                                          }}
                                                                                    >
                                                                                          Dealer
                                                                                          %
                                                                                    </th>
                                                                                    <th
                                                                                          style={{
                                                                                                width: "90px",
                                                                                          }}
                                                                                    >
                                                                                          Stock
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {products.length >
                                                                              0 ? (
                                                                                    products.map(
                                                                                          (
                                                                                                product,
                                                                                                index
                                                                                          ) => (
                                                                                                <tr
                                                                                                      key={
                                                                                                            index
                                                                                                      }
                                                                                                >
                                                                                                      <td>
                                                                                                            {
                                                                                                                  ++index
                                                                                                            }
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <div className="d-flex">
                                                                                                                  <span>
                                                                                                                        <div className="d-flex align-items-center">
                                                                                                                              {product.image && (
                                                                                                                                    <div className="flex-shrink-0 me-3">
                                                                                                                                          <div className="avatar-sm bg-light rounded p-1 h-100">
                                                                                                                                                <img
                                                                                                                                                      src={
                                                                                                                                                            product.image
                                                                                                                                                      }
                                                                                                                                                      alt={
                                                                                                                                                            product.name
                                                                                                                                                      }
                                                                                                                                                      className="img-fluid d-block"
                                                                                                                                                />
                                                                                                                                          </div>
                                                                                                                                    </div>
                                                                                                                              )}
                                                                                                                              <div className="flex-grow-2">
                                                                                                                                    <h5 className="fs-6 mb-1">
                                                                                                                                          {
                                                                                                                                                product.title
                                                                                                                                          }
                                                                                                                                    </h5>
                                                                                                                                    <p className="text-muted mb-0 fs-6">
                                                                                                                                          Brand
                                                                                                                                          :{" "}
                                                                                                                                          <span className="fw-medium pe-3">
                                                                                                                                                {
                                                                                                                                                      product.brand
                                                                                                                                                }
                                                                                                                                          </span>
                                                                                                                                    </p>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </span>
                                                                                                            </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <FormControl
                                                                                                                  defaultValue={
                                                                                                                        product.price
                                                                                                                  }
                                                                                                                  onBlur={(
                                                                                                                        event
                                                                                                                  ) =>
                                                                                                                        handleProductPrice(
                                                                                                                              event
                                                                                                                                    .target
                                                                                                                                    .value,
                                                                                                                              product,
                                                                                                                              "mrp_price"
                                                                                                                        )
                                                                                                                  }
                                                                                                            />
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <FormControl
                                                                                                                  defaultValue={
                                                                                                                        product.seller_price
                                                                                                                  }
                                                                                                                  onBlur={(
                                                                                                                        event
                                                                                                                  ) =>
                                                                                                                        handleProductPrice(
                                                                                                                              event
                                                                                                                                    .target
                                                                                                                                    .value,
                                                                                                                              product,
                                                                                                                              "retailer_price"
                                                                                                                        )
                                                                                                                  }
                                                                                                            />
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <FormControl
                                                                                                                  defaultValue={
                                                                                                                        product.dealer_price
                                                                                                                  }
                                                                                                                  onBlur={(
                                                                                                                        event
                                                                                                                  ) =>
                                                                                                                        handleProductPrice(
                                                                                                                              event
                                                                                                                                    .target
                                                                                                                                    .value,
                                                                                                                              product,
                                                                                                                              "dealer_price"
                                                                                                                        )
                                                                                                                  }
                                                                                                            />
                                                                                                      </td>
                                                                                                      <td>
                                                                                                            <FormControl
                                                                                                                  defaultValue={
                                                                                                                        product.quantity
                                                                                                                  }
                                                                                                                  onBlur={(
                                                                                                                        event
                                                                                                                  ) =>
                                                                                                                        handleProductPrice(
                                                                                                                              event
                                                                                                                                    .target
                                                                                                                                    .value,
                                                                                                                              product,
                                                                                                                              "quantity"
                                                                                                                        )
                                                                                                                  }
                                                                                                            />
                                                                                                      </td>
                                                                                                </tr>
                                                                                          )
                                                                                    )
                                                                              ) : (
                                                                                    <tr className="text-center">
                                                                                          <td
                                                                                                colSpan={
                                                                                                      9
                                                                                                }
                                                                                          >
                                                                                                No product found
                                                                                          </td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </Table>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                          </Row>
                                    </Container>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default ProductPrice;
