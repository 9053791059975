import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const CategoryFilterContainer = styled(Accordion)`
      padding-right: 10px;

      li {
            list-style: none;
      }
`;

export const CategoryFilterHeader = styled(Accordion.Header)`
      button {
            padding: 0;
            background-color: transparent !important;
            box-shadow: none !important;
            font-weight: 400;
            font-size: 1rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            padding: 10px;
      }
`;

export const CategoryFilterBody = styled(Accordion.Body)`
      padding: 0;

      ul {
            padding-left: 16px;

            li {
                  border-bottom: 1px solid #dee2e6;
                  padding-bottom: 5px;
                  padding-top: 5px;
                  list-style-type: none;

                  label {
                        padding: 0 10px;
                  }

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }

            .accordion {
                  border-bottom: 1px solid #dee2e6 !important;

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }
      }
`;
